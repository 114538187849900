import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import { border, color, shadow, transition, typography } from 'style';

const SelectTabRadioLabel = styled.label`
  ${typography.button};
  background-color: ${color.ui01};
  border-top: ${border.normal(color.ui15)};
  border-bottom: ${border.normal(color.ui15)};
  border-left: ${border.normal(color.ui15)};
  color: ${color.text600};
  cursor: pointer;
  display: inline-block;
  line-height: 3.8rem;
  padding: 0 1.6rem;
  text-align: center;
  transition: ${transition.primary('background-color')}, ${transition.primary('border-color')};

  .select-tab-radio--first & {
    border-top-left-radius: ${border.radius.normal};
    border-bottom-left-radius: ${border.radius.normal};
  }

  .select-tab-radio--last & {
    border-top-right-radius: ${border.radius.normal};
    border-bottom-right-radius: ${border.radius.normal};
    border-right: ${border.normal(color.ui15)};
  }

  &:hover {
    background-color: ${color.ui10};
  }

  &:focus {
    background-color: ${color.ui10};
    border-color: ${color.ui01};
    box-shadow: ${shadow.focus(color.ui15)};
  }

  &.disabled {
    background-color: ${color.ui01};
    color: ${color.textHint};
    border-top: ${border.normal(color.ui30)};
    border-bottom: ${border.normal(color.ui30)};
    border-left: ${border.normal(color.ui30)};
    cursor: not-allowed;

    .select-tab-radio--last & {
      border-right: ${border.normal(color.ui30)};
    }
  }

  &.checked {
    background-color: ${color.mxDeepOcean};
    border: ${border.normal(color.mxDeepOcean)};
    color: ${color.textWhite};

    &:hover {
      background-color: ${color.mxDeepOcean};
      border: ${border.normal(color.mxDeepOcean)};
      cursor: default;
    }

    &:focus {
      background-color: ${color.mxDeepOceanHover};
      border: ${border.normal(color.ui01)};
      box-shadow: ${shadow.focus(color.mxDeepOceanHover)};
    }

    &.disabled {
      background-color: ${color.ui05};
      color: ${color.textHint};
      border-top: ${border.normal(color.ui30)};
      border-bottom: ${border.normal(color.ui30)};
      border-left: ${border.normal(color.ui30)};
      border-right: 0;
      cursor: not-allowed;

      .select-tab-radio--last & {
        border-right: ${border.normal(color.ui30)} !important;
      }
    }
  }
`;

const Input = styled.input`
  left: -9999rem;
  position: absolute;
`;

const SelectTabRadio = ({
  checked,
  children,
  className,
  dataTest,
  disabled,
  id: _id,
  name,
  onChange,
  value,
}) => {
  const id = _id || value + name;

  return (
    <div
      className={className}
      data-test={dataTest}
    >
      <Input
        aria-checked={checked}
        aria-labelledby={id}
        checked={checked}
        data-test={dataTest && `${dataTest}-input`}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        role="radio"
        type="radio"
        value={value}
      />
      <SelectTabRadioLabel
        htmlFor={id}
        data-test={dataTest && `${dataTest}-label`}
        className={classnames({ checked, disabled })}
      >
        {children}
      </SelectTabRadioLabel>
    </div>
  );
};

SelectTabRadio.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

SelectTabRadio.defaultProps = {
  checked: false,
  children: [],
  className: undefined,
  dataTest: undefined,
  disabled: false,
  id: undefined,
};

export { SelectTabRadio };
