import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { border, color, typography } from 'style';
import { Content } from './Content';
import { Overlay } from './Overlay';

const Title = styled.h3`
  ${typography.heading3}
  margin-bottom: 2.4rem;
`;

const ConfirmationContainer = styled(Content)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const ConfirmationContent = styled.div`
  ${typography.bodyLarge}
  flex: 1;
  padding: 3.2rem 2.4rem;
  border-top-left-radius: ${border.radius.normal};
  border-top-right-radius: ${border.radius.normal};

  ${({ isNegative }) => isNegative && `
    background-color: ${color.negative10};
  `}
`;

const ButtonFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem 2.4rem;
  background-color: ${color.ui05};
  border-bottom-left-radius: ${border.radius.normal};
  border-bottom-right-radius: ${border.radius.normal};

  button,
  a {
    margin-left: 1.6rem;
  }
`;

const ConfirmationModal = ({ children, className, closeClick, title, buttons, isNegative }) => (
  <Overlay>
    <ConfirmationContainer
      closeClick={closeClick}
      className={className}
    >
      <ConfirmationContent isNegative={isNegative} data-test="confirmation-modal-content">
        <Title>{title}</Title>
        {children}
      </ConfirmationContent>

      <ButtonFooter>
        {buttons.map(button => React.cloneElement(button, { key: button.key }))}
      </ButtonFooter>
    </ConfirmationContainer>
  </Overlay>
);

ConfirmationModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.array.isRequired,
  isNegative: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  className: undefined,
  isNegative: false,
};

export { ConfirmationModal };
