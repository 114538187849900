import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const TableCellTooltip = ({ children }) => {
  const [title, setTitle] = useState('');
  const cellRef = useRef();
  const calculateWidth = () => {
    let paddingOffset;
    const tableCell = cellRef.current.closest('[role="cell"]');
    try {
      const cs = getComputedStyle(cellRef.current.closest('[role="cell"]'));
      paddingOffset = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
    } catch (error) {
      paddingOffset = 0;
    }
    const childWidth = cellRef.current.clientWidth || 0;
    if ((childWidth + paddingOffset) >= tableCell.clientWidth) {
      setTitle(cellRef.current.textContent || '');
    } else {
      setTitle('');
    }
  };

  const removeTooltip = () => {
    setTitle('');
  };

  return (
    <div
      ref={cellRef}
      {...(title && { title })}
      onMouseLeave={removeTooltip}
      data-tag="allowRowEvents"
    >
      <div onMouseOver={() => calculateWidth()} data-tag="allowRowEvents">
        {children}
      </div>
    </div>
  );
};

TableCellTooltip.propTypes = {
  actionLink: PropTypes.node,
  dataTest: PropTypes.string,
  children: PropTypes.node,
};

export { TableCellTooltip };
