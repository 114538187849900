import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, typography, bootstrapOverrides } from 'style';
import { ErrorCircle } from '@gsc/icons/react';

const Wrapper = styled.div`
  ${bootstrapOverrides.errorWrapper};
  align-items: flex-start;
  display: flex;
  min-height: 2.4rem;
  padding-top: 0.4rem;
  visibility: ${({ message }) => message ? 'visible' : 'hidden'};
`;

const ErrorIcon = styled(ErrorCircle)`
  align-self: flex-start;
  color: ${color.negative50};
  flex: 0 0 1.2rem;
  margin: 0 0.4rem 0 0;
`;

const ErrorMessage = styled.div`
  ${typography.bodySmall}
  color: ${color.negative50};
  position: relative;
  top: -0.1rem;
`;

const Error = ({ className, dataTest, message, ...rest }) => (
  <Wrapper
    className={className}
    data-test={dataTest}
    message={message}
    {...rest}
  >
    {message && <ErrorIcon />}
    <ErrorMessage>
      {(message && typeof message === 'object')
        ? message.map(error => (<Fragment key={error}>{error}<br /></Fragment>))
        : message
      }
    </ErrorMessage>
  </Wrapper>
);

Error.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

Error.defaultProps = {
  dataTest: undefined,
  className: undefined,
};

export { Error };
