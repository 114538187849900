export const spacing = {
  '3xs': '0.2rem',
  '2xs': '0.4rem',
  'xs': '0.8rem',
  'sm': '1.2rem',
  'md': '1.6rem',
  'lg': '2.4rem',
  'xl': '3.2rem',
  '2xl': '4rem',
  '3xl': '4.8rem',
};
