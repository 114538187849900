import styled from 'styled-components';
import { color } from 'style';

const colorMap = {
  default: color.text2,
  info: color.info50,
  positive: color.positive50,
  warning: color.warning50,
  negative: color.negative50,
};

interface DropdownMenuItemProps {
  active?: boolean,
  dataTest?: string,
  disabled?: boolean,
  status?: 'default' | 'info' | 'positive' | 'warning' | 'negative'
};

const DropdownMenuItem = styled.li.attrs(
  ({ dataTest }: DropdownMenuItemProps) => ({ 'data-test': dataTest }),
)(({ active, disabled, status }: DropdownMenuItemProps) => `
  background-color: ${active ? color.ui10 : color.ui01};
  color: ${status ? colorMap[status] : color.text2};
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  line-height: 4rem;
  padding: 0 1.2rem;
  text-align: left;
  width: 100%;

  ${disabled ? `
    color: ${color.textHint};
    cursor: not-allowed;
  ` : ''}
`);

export { DropdownMenuItem };
