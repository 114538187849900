import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, transition, typography, zIndex, border, elevation } from 'style';
import { Close } from '@gsc/icons/react';
import { Notification } from '../Notification';

const xTranslation = ({ isRight, isOpen }) => {
  if (isOpen) {
    return 'translate(0,0)';
  }
  return isRight ? 'translate(100%,0)' : 'translate(-100%,0)';
};

const xAnchor = ({ isRight }) => (isRight ? 'right: 0' : 'left: 0');

const Wrapper = styled.div`
  ${typography.button};
  background-color: ${color.ui01};
  border-left: ${border.normal(color.ui30)};
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  z-index: ${zIndex.alpha};
  ${xAnchor};
  ${({ isOpen }) => (isOpen && elevation['16'])}
  transform: ${xTranslation};
  transition: ${transition.primary('transform')};
`;
Wrapper.displayName = 'FlyoutWrapper';

const FlyoutContent = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  padding: 3.2rem;
  width: 40rem;
`;

const FlyoutHeader = styled.div`
  margin-bottom: 2.6rem;
  width: 33.6rem;
  &:after {
    background-color: ${color.mxDeepOcean};
    content: '';
    display: inline-block;
    height: 0.4rem;
    margin-top: 1.6rem;
    width: 8rem;
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled.h2`
  color: ${color.text1};
  ${typography.heading2}
`;

const CloseFlyOut = styled(Close)`
  cursor: pointer;
  color: ${color.ui30};
  height: 2rem;
  width: 2rem;
`;

const FlyoutBody = styled.div`
  margin-bottom: 1.2rem;
  width: 33.6rem;
`;

const FlyoutFooter = styled.div`
  background-color: ${color.ui05};
  display: flex;
  flex: 0 0 auto;
  position: relative;
  justify-content: flex-end;
  padding: 2.4rem;
`;

const ErrorNotification = styled(Notification)`
  ${elevation['16']};
  left: 3.2rem;
  position: absolute;
  top: 3.2rem;
  width: 33.6rem;
  z-index: 100;
`;

const MarketXFlyout = ({ buttons, children, className, dataTest, isError, isOpen, isRight, handleClose, handleErrorClose, headerText, errorMessage }) => (
  <Wrapper
    className={className}
    isOpen={isOpen}
    isRight={isRight}
    data-test={dataTest}
  >
    <FlyoutContent>
      {isError && errorMessage && (
        <ErrorNotification
          status="negative"
          dataTest={dataTest && `${dataTest}-error-notification`}
          onClick={handleErrorClose}
        >
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </ErrorNotification>
      )}
      {headerText && (
        <FlyoutHeader>
          <TitleBar>
            <HeaderText data-test={dataTest && `${dataTest}-header`}>
              {headerText}
            </HeaderText>
            <CloseFlyOut
              onClick={handleClose}
              data-test={dataTest && `${dataTest}-close-button`}
            />
          </TitleBar>
        </FlyoutHeader>
      )}
      <FlyoutBody>
        {children}
      </FlyoutBody>
    </FlyoutContent>
    {buttons.length > 0 && (
      <FlyoutFooter data-test={dataTest && `${dataTest}-footer`}>
        {buttons.map(button => React.cloneElement(button, { key: button.key, isInline: true }))}
      </FlyoutFooter>)
    }
  </Wrapper>
);

MarketXFlyout.propTypes = {
  buttons: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  errorMessage: PropTypes.string,
  handleClose: PropTypes.func,
  handleErrorClose: PropTypes.func,
  headerText: PropTypes.string,
  isOpen: PropTypes.bool,
  isRight: PropTypes.bool,
  isError: PropTypes.bool,
};

MarketXFlyout.defaultProps = {
  buttons: [],
  children: [],
  className: undefined,
  dataTest: undefined,
  errorMessage: undefined,
  handleClose: () => {},
  handleErrorClose: () => {},
  headerText: undefined,
  isOpen: false,
  isRight: false,
  isError: false,
};

export { MarketXFlyout };