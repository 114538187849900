import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { border, color, shadow, transition, typography } from 'style';

const StyledCircleSelectCheckbox = styled.div`
  input:checked + label {
    background-color: ${color.mxDeepOcean};
    border: ${border.normal(color.mxDeepOcean)};
    color: ${color.textWhite};

    &:hover {
      background-color: ${color.mxDeepOceanHover};
      border: ${border.normal(color.mxDeepOceanHover)};
    }

    &:focus {
      background-color: ${color.mxDeepOceanHover};
      border: ${border.normal(color.ui01)};
      box-shadow: ${shadow.focus(color.mxDeepOceanHover)};
    }
  }
`;

const CircleSelectCheckboxLabel = styled.label`
  ${typography.button};
  background-color: ${color.ui01};
  border-radius: 999rem;
  border: ${border.normal(color.ui15)};
  color: ${color.text600};
  cursor: pointer;
  display: inline-block;
  line-height: 3.8rem;
  padding: 0;
  text-align: center;
  transition: ${transition.primary('background-color')}, ${transition.primary('border-color')};
  width: 4rem;

  &:hover {
    background-color: ${color.ui10};
  }

  &:focus {
    background-color: ${color.ui10};
    border-color: ${color.ui01};
    box-shadow: ${shadow.focus(color.ui15)};
  }

  &:disabled {
    color: ${color.textWhite};
    background-color: ${color.ui15};
    border-color: ${color.ui15};
    cursor: not-allowed;
  }
`;

const Input = styled.input`
  left: -9999rem;
  position: absolute;
`;

const CircleSelectCheckbox = ({
  checked,
  children,
  className,
  dataTest,
  disabled,
  id: _id,
  name,
  onChange,
  value,
}) => {
  const id = _id || value + name;

  return (
    <StyledCircleSelectCheckbox
      className={className}
      data-test={dataTest}
    >
      <Input
        aria-checked={checked}
        aria-labelledby={id}
        checked={checked}
        data-test={dataTest && `${dataTest}-input`}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        role="checkbox"
        type="checkbox"
        value={value}
      />
      <CircleSelectCheckboxLabel
        htmlFor={id}
        data-test={dataTest && `${dataTest}-label`}
      >
        {children}
      </CircleSelectCheckboxLabel>
    </StyledCircleSelectCheckbox>
  );
};

CircleSelectCheckbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

CircleSelectCheckbox.defaultProps = {
  checked: false,
  children: [],
  className: undefined,
  dataTest: undefined,
  disabled: false,
  id: undefined,
};

export { CircleSelectCheckbox };
