/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { color, zIndex } from 'style';
import { DropdownMenu, DropdownMenuItem } from '../Dropdown';
import { Error } from '../Error';
import { Label } from '../Label';
import { TypeaheadInput } from './TypeaheadInput';
import { MultiDownshift } from './MultiDownshift';

const TypeaheadWrapper = styled(({ rootRef, ...rest }) => (
  <div ref={rootRef} {...rest} />
))`
  color: ${color.text600};
  font-size: 1.4rem;
`;

const MenuContainer = styled.div`
  position: relative;
`;

const TypeaheadMenu = styled(DropdownMenu)`
  left: 0;
  position: absolute;
  top: calc(100% + 0.4rem);
  width: 100%;
  z-index: ${zIndex.echo};
`;

const TypeaheadLabel = styled(Label)`
  margin: 0;
  padding: 0;
`;

const ErrorWrapper = styled(Error)`
  margin: 0.4rem 0;
`;

const Typeahead = ({
  className,
  dataTest,
  disabled,
  errors,
  id,
  onChange,
  renderLabelContent,
  items,
  itemDisabled,
  itemLabel,
  maxListSize,
  placeholder,
  selectedItems: selectedItemsProp,
  inputProps,
  leaveSpaceForError,
}) => (
  <MultiDownshift
    itemToString={itemLabel}
    onChange={onChange}
    selectedItems={selectedItemsProp}
  >
    {({
      getItemProps,
      getLabelProps,
      getMenuProps,
      toggleItem,
      getRootProps,
      highlightedIndex,
      inputValue,
      isOpen,
      openMenu,
      getInputPropsWithBackspace,
      selectedItems,
    }) => {
      const filteredItems = items
        .filter(
          item =>
            !selectedItems.includes(item) &&
            itemLabel(item)
              .toLowerCase()
              .includes(inputValue.toLowerCase()),
        )
        .slice(0, maxListSize);
      const showMenu = isOpen && !disabled;
      const labelContent = renderLabelContent();

      return (
        <TypeaheadWrapper
          {...getRootProps({ refKey: 'rootRef', className, id })}
        >
          {labelContent && (
            <TypeaheadLabel {...getLabelProps()}>{labelContent}</TypeaheadLabel>
          )}
          <MenuContainer>
            <TypeaheadInput
              disabled={disabled}
              onFocus={openMenu}
              itemLabel={itemLabel}
              inputProps={{ ...getInputPropsWithBackspace(inputProps) }}
              onRemove={toggleItem}
              selectedItems={selectedItems}
              inputValue={inputValue}
              placeholder={placeholder}
              dataTest={dataTest}
              hasError={!!errors}
            />
            <TypeaheadMenu
              hidden={!showMenu}
              {...getMenuProps({
                dataTest: dataTest && `${dataTest}-menu`,
              })}
            >
              {filteredItems.length === 0 ? (
                <DropdownMenuItem disabled>No results found</DropdownMenuItem>
              ) : (
                filteredItems.map((item, index) => (
                  <DropdownMenuItem
                    key={itemLabel(item)}
                    {...getItemProps({
                      item,
                      active: highlightedIndex === index,
                      disabled: itemDisabled(item),
                    })}
                  >
                    {itemLabel(item)}
                  </DropdownMenuItem>
                ))
              )}
            </TypeaheadMenu>
          </MenuContainer>
          {(errors || leaveSpaceForError) && (
            <ErrorWrapper
              message={errors}
              data-test={dataTest && `${dataTest}-error`}
            />
          )}
        </TypeaheadWrapper>
      );
    }}
  </MultiDownshift>
);

Typeahead.defaultProps = {
  className: undefined,
  dataTest: 'typeahead',
  disabled: false,
  id: undefined,
  itemDisabled: () => false,
  itemLabel: item => item,
  items: [],
  maxListSize: 10,
  onChange: () => {},
  renderLabelContent: () => {},
  placeholder: 'Enter one or more values',
  selectedItems: [],
};

Typeahead.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  itemDisabled: PropTypes.func,
  itemLabel: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ),
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ),
  renderLabelContent: PropTypes.func,
  maxListSize: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export { Typeahead };
