/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { color, border, mixin } from 'style';
import { TertiaryButton } from '../Button';
import { Loader } from '../Loader';
import { H3 } from '../Heading';
import { CSVImage, RoutingImage, XLSXImage } from '../images';
import { AccordionExpandedSolid, Download } from '@gsc/icons/react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { SearchForm } from '../MarketXSearchForm';
import useEffectOnUpdate from '../helpers/useEffectOnUpdate';
import currentDateAsEpoch from '../helpers/currentDateAsEpoch';
import { Link } from '../Link';
import { MarketXTooltip } from '../MarketXTooltip';
import { Checkbox } from '../Checkbox';
import { ButtonMenu } from '../ButtonMenu';
import { TableColumnsContext, TableColumnsProvider } from './TableColumnsContext';
import isEqual from 'lodash/isEqual';

const propTypes = {
  records: PropTypes.array.isRequired,
  noResultsMessage: PropTypes.string,
  disabledSelectableRowTooltipContent: PropTypes.string,
  initialPageSize: PropTypes.oneOf([5, 10, 20, 25, 50, 100]),
  initialSortColumn: PropTypes.string,
  initialPage: PropTypes.number,
  fetchRecords: PropTypes.func,
  onTableReset: PropTypes.func,
  selectableRowDisabled: PropTypes.func,
  onSelectedRowsChange: PropTypes.func,
  canBeReset: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  selectableRows: PropTypes.bool,
  ttl: PropTypes.number,
  useLocalStorage: PropTypes.bool,
  clearSelectedRows: PropTypes.bool,
  newStyling: PropTypes.bool,
  handleRowMouseEnter: PropTypes.func,
  handleRowMouseLeave: PropTypes.func,
  customEmptyComponent: PropTypes.node,
  pagination: PropTypes.bool,
  exportTypes: PropTypes.arrayOf(PropTypes.string),
  conditionalRowStyles: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  initialSortColumn: 'id',
  initialSortDirection: 'asc',
  canBeReset: true,
  fetchRecords: () => {},
  onTableReset: () => {},
  disabledSelectableRowTooltipContent: 'Additional permission required',
  searchEnabled: true,
  selectableRows: false,
  noResultsMessage: 'No records found',
  ttl: 7200, // two hours
  useLocalStorage: true,
  newStyling: false,
  clearSelectedRows: false,
  pagination: true,
  exportTypes: ['csv'],
};

const TableWrapper = styled.div`
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui15)};

  & .rdt_Pagination > div > select {
    background: none;
    box-shadow: none;
  }
  
  .rdt_TableCol_Sortable:hover {
    opacity: 1;

    [class*='rdt_custom_sort_icon'], [class*='rdt_custom_sort_icon'] * {
      opacity: 1;
    }
  }
`;

const ButtonMenuWrapper = styled(ButtonMenu)`
  ul {
    min-width: 10rem;
  }
  li {
    padding: 0.2rem 1.2rem;
  }
`;

const SortIconWrapper = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  display: flex;
  padding: 0 1rem;
  color: ${color.text900};

  svg {
    font-size: 1rem !important;
    width: 1rem !important;
    height: 1rem !important;
  }
`;

const NewSortIconWrapper = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  display: flex;
  padding-left: .6rem;
  color: ${color.text900};

  svg {
    font-size: .8rem !important;
    width: .8rem !important;
    height: .8rem !important;
  }
`;

const StyledSkeletonLoader = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  align-self: center;
  justify-self: center;
  height: ${props => props.newStyling ? '1.6rem;' : '2rem;'};
  width: 15rem;
`;

const newStyleSearch = `
  margin-left: 0;
    input {
      font-size:1.2rem;
      height: 2.8rem;
      padding-left: 3.2rem;
    };
    svg {
      top: 0.6rem;
      left: 0.8rem;
    }
  `;

const Search = styled(SearchForm)`
${props => props.newStyling ?
    newStyleSearch
    :
    'margin-left: 0' }
`;

const NoResultsDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 3rem;
  flex-direction: column;
  gap: 2rem;

  img {
    width: 7rem;
  }
`;

const StyledLoader = styled(Loader)`
  ${mixin.size('8rem')}
  color: ${color.text600};
`;

const ExportButton = styled(TertiaryButton)`
  justify-self: flex-end;
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const CheckboxWrapper = styled.div.attrs(({ dataGscCellId }) => ({ 'data-gsc-cell-id': dataGscCellId }))`
  height:3rem;
  padding-top:0.6rem;
`;


const LoaderWrapper = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  padding: 8rem 2.4rem 2.4rem;
`;

const TableFiltering = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  flex-wrap: wrap;
  gap: 1rem;
  ${props => props.newStyling && `
    align-items: flex-start;
    flex-direction: column;
    gap: 1.2rem;
  `}
`;

const TableTitleWrapper = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  align-items: center;
  display: flex;
  justify-self: flex-start;
  gap: 1rem;
`;

const TableTitle = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  font-size: 1.5rem;
  font-weight: 500;
  flex-shrink: 0;
`;

const BulkActions = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  justify-self: flex-start;
  display: flex;

  button, a {
    color: ${color.mxDeepOcean};
  }
`;

const TableActions = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  border-bottom: ${border.normal(color.ui15)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 1rem;
  width: 100%;
`;

const getStyleOverrides = ({ newStyling, rowStyling }) => {
  // Once we get more core tables moved to the component lib table, we can remove this
  if (!newStyling) return {
    headRow: {
      style: {
        backgroundColor: color.ui05,
        minHeight: '38px',
      },
    },
    pagination: {
      style: {
        backgroundColor: 'transparent',
      },
    },
  };

  return {
    headRow: {
      style: {
        minHeight: '32px',
      },
    },
    headCells: {
      style: {
        paddingLeft:'8px',
        paddingRight:'8px',
      },
    },
    pagination: {
      style: {
        backgroundColor: 'transparent',
      },
    },
    rows: rowStyling,
    cells: {
      style: {
        padding: '8px',
        fontSize: '12px',
        alignItems: 'start !important',
        minHeight: '32px',
      },
    },
  };
};

const defaultTableParams = {
  page: 1,
  pageSize: 50,
  sortColumn: 'id',
  sortDirection: 'asc',
  searchValue: '',
};

const Table = ({
  bulkActions,
  canBeReset,
  className,
  clearSelectedRows,
  columns,
  dataTest,
  disabledSelectableRowTooltipContent,
  fetchRecords,
  filterLinks,
  handleRowClicked,
  handleRowMouseEnter,
  handleRowMouseLeave,
  initialPage,
  initialPageSize,
  initialSortColumn,
  initialSortDirection,
  isLoading,
  noResultsMessage,
  onExportClick,
  onSelectedRowsChange,
  records,
  recordsCount,
  tableTitle,
  tableDescription,
  searchEnabled,
  selectableRows,
  selectableRowDisabled,
  ttl,
  useLocalStorage,
  localStorageIdentifier,
  newStyling,
  customEmptyComponent,
  pagination,
  exportTypes,
  onTableReset,
  conditionalRowStyles,
}) => {
  const initialTableParams = {
    page: initialPage || defaultTableParams.page,
    pageSize: initialPageSize || defaultTableParams.pageSize,
    sortColumn: initialSortColumn || defaultTableParams.sortColumn,
    sortDirection: initialSortDirection || defaultTableParams.sortDirection,
    searchValue: defaultTableParams.searchValue,
  };

  const tableClassName = className || 'gospotcheck-table-wrapper';

  const resolveInitialTableParams = () => {
    if (useLocalStorage) {
      const lsItem = getTableState();
      if (lsItem && (currentDateAsEpoch() - lsItem.updatedAt) <= ttl) {
        return lsItem;
      } else {
        saveTableState(initialTableParams);

        return initialTableParams;
      }
    } else {
      return initialTableParams;
    }
  };

  const getTableState = () => {
    return JSON.parse(localStorage.getItem(localStorageKey));
  };

  const saveTableState = (params) => {
    if (useLocalStorage) {
      const updatedAt = { updatedAt: currentDateAsEpoch() };
      localStorage.setItem(localStorageKey, JSON.stringify({ ...params, ...updatedAt }));
    }
  };

  const localStorage = window.localStorage;
  const localStorageKeyDefault = `marketx_datatable_${window.location.pathname}`;
  const localStorageKey = localStorageIdentifier ? `marketx_datatable_${localStorageIdentifier}_${window.location.pathname}` : localStorageKeyDefault;
  const [tableParams, setTableParams] = useState(resolveInitialTableParams());
  const [searchValue, setSearchValue] = useState(tableParams.searchValue);
  const [tableKey, setTableKey] = useState(0);
  const LoaderRows = [...Array(10).keys()].map((i) => i);
  const [tableRecords, setTableRecords] = useState(isLoading ? LoaderRows : records);
  const [searchFormKey, setSearchFormKey] = useState(0);
  const columnsRef = useRef();

  useEffect(() => {
    const colIds = columns.map((col) => col.id);

    if (!columnsRef.current || columnsRef.current === []) {
      columnsRef.current = columns.map((col) => col.id);
    } else if (!isEqual(columnsRef.current, colIds)) {
      columnsRef.current = columns.map((col) => col.id);
      setTableKey(tableKey + 1);
    }
  }, [columns]);

  const CustomSortIcon = () => (
    newStyling ?
      <NewSortIconWrapper dataTest="column-sort-icon">
        <AccordionExpandedSolid />
      </NewSortIconWrapper>
      :
      <SortIconWrapper dataTest="column-sort-icon" >
        <AccordionExpandedSolid />
      </SortIconWrapper>
  );

  const resetTable = () => {
    onTableReset && onTableReset();
    setSearchFormKey(searchFormKey + 1);
    setTableKey(tableKey + 1);
    saveTableState(defaultTableParams);
    setTableParams(defaultTableParams);
  };

  useEffect(() => {
    if (isLoading) {
      records = LoaderRows;
    }
  }, []);

  useEffect(() => {
    setTableRecords(records);
  }, [records]);

  const NoResults = () => (
    <NoResultsWrapper>
      <RoutingImage />
      <NoResultsDescription>
        <H3>{`${ noResultsMessage }.`}</H3>
        {canBeReset && (
          <div>
            If this seems like an error,&nbsp;
            <Link dataTest={`${dataTest}-reset-table`} onClick={resetTable}>refresh this table.</Link>
          </div>
        )}
      </NoResultsDescription>

    </NoResultsWrapper>
  );
  const NoResultCustom = () => {
    return (
      <NoResultsWrapper>
        <NoResultsDescription>
          {customEmptyComponent}
          {canBeReset && (
            <div>
              If this seems like an error,&nbsp;
              <Link dataTest={`${dataTest}-reset-table`} onClick={resetTable}>
                refresh this table.
              </Link>
            </div>
          )}
        </NoResultsDescription>
      </NoResultsWrapper>
    );
  };

  const CustomLoader = () => (
    <LoaderWrapper dataTest={`${dataTest}-loading`}>
      <StyledLoader />
    </LoaderWrapper>
  );

  /* eslint-disable-next-line react/display-name */
  const SelectCheckbox = React.forwardRef((props, ref) => {
    return (
      newStyling ?
        <CheckboxWrapper dataGscCellId="gospotcheck-row-select-checkbox">
          <Checkbox ref={ref} dataTest={`${dataTest}-checkbox`} isSmall disabledTooltipContent={disabledSelectableRowTooltipContent} { ...props}/>
        </CheckboxWrapper>
        :
        <Checkbox ref={ref} dataTest={`${dataTest}-checkbox`} isSmall disabledTooltipContent={disabledSelectableRowTooltipContent} { ...props}/>
    );
  });

  useEffectOnUpdate(() => {
    saveTableState(tableParams);
    fetchRecords(tableParams);
  }, [tableParams]);

  const ExportButtonsWrapper = () => {
    const exportTypesMap = {
      csv: {  label: 'CSV', icon: <CSVImage />, value: 'csv' },
      xlsx: {  label: 'XLSX', icon: <XLSXImage />, value: 'xlsx' },
    };

    const getExportButton = (type) => {
      const exportDetails = exportTypesMap[type];
      if (!exportDetails) return <></>;

      return (
        <>
          <ExportButton
            isXSmall
            status="neutral"
          >
            {exportDetails.icon || <Download />}
            <span data-test={`${dataTest}-export-button-${type}`}>{exportDetails.label}</span>
          </ExportButton>
        </>
      );
    };

    if (!!exportTypes && exportTypes.length > 1) {
      return (
        <ButtonMenuWrapper
          buttonText="Export"
          items={ exportTypes.map((type) => {
            return { children: getExportButton(type), onSelect: () => onExportClick(searchValue, exportTypesMap[type].value) };
          })}
          dataTest={`${dataTest}-export-button`}
          alignRight={true}
          buttonProps={ { isXSmall: true } }
          isIconLeft={true}
          variant="tertiary"
          icon={<Download/>}
          disableArrow
        />
      );
    } else {
      const type = !!exportTypes ? exportTypesMap[exportTypes[0]] : exportTypesMap['csv'];
      return (
        <>
          <ExportButton
            isXSmall
            data-test={`${dataTest}-export-button`}
            onClick={() => onExportClick(searchValue, type.value)}
            status="neutral"
          >
            <Download />
            <span data-test={`${dataTest}-export-button-${type}`}>{type.label}</span>
          </ExportButton>
        </>
      );
    }
  };

  const onChangeSort = (column, direction) => {
    setTableParams({ ...tableParams, sortColumn: column.id, sortDirection: direction });
  };

  useEffect(() => {
    setTableParams({ ...tableParams, searchValue });
  }, [searchValue]);

  const onChangeSearchValue = (value) => {
    // need to set search in an effect so the debounce does not save old state
    setSearchValue(value);
  };

  const onChangePage = (number) => {
    setTableParams({ ...tableParams, page: number });
  };

  const onChangeRowsPerPage = (number) => {
    setTableParams({ ...tableParams, pageSize: number });
  };
  const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
  return (
    <>
      {(searchEnabled || filterLinks) && (
        <TableFiltering dataTest={`${dataTest}-header`} newStyling={newStyling}>
          {filterLinks}
          {searchEnabled && (
            <Search
              newStyling={newStyling}
              key={searchFormKey}
              className="search-form"
              dataTest={dataTest}
              name="search-form"
              onSubmit={onChangeSearchValue}
              value={tableParams.searchValue}
            />
          )}
        </TableFiltering>
      )}

      <TableWrapper data-test={dataTest} key={tableKey}>
        {(tableTitle || tableDescription || onExportClick) && (
          <TableActions dataTest={`${dataTest}-actions-row`}>
            <TableTitleWrapper dataTest={`${dataTest}-title-wrapper`}>
              {tableTitle && !tableDescription && (
                <TableTitle dataTest={`${dataTest}-title`}>
                  {tableTitle}
                </TableTitle>
              )}
              {tableDescription && (
                <MarketXTooltip
                  dataTest={dataTest}
                  hoverContent={tableTitle ? (
                    <TableTitle dataTest={`${dataTest}-title`}>{tableTitle}</TableTitle>
                  ) : null}
                >
                  <div data-test={`${dataTest}-description`}>
                    {tableDescription}
                  </div>
                </MarketXTooltip>
              )}
              {bulkActions && (
                <BulkActions>
                  {bulkActions}
                </BulkActions>
              )}
            </TableTitleWrapper>
            {onExportClick && (
              <ExportButtonsWrapper />
            )}
          </TableActions>
        )}
        <TableColumnsProvider
          className={tableClassName}
          isLoading={isLoading}
          tableColumns={columns}
          dataTest={dataTest}
          newStyling={newStyling}
          selectableRows={selectableRows}
        >
          <TableColumnsContext.Consumer>
            {({ TableColumns, rowStyling }) => {
              return (
                <DataTable
                  noHeader
                  className={tableClassName}
                  columns={TableColumns}
                  data={tableRecords}
                  isLoading={isLoading}
                  selectableRowsComponent={SelectCheckbox}
                  selectableRowsComponentProps={selectProps}
                  defaultSortFieldId={tableParams.sortColumn}
                  defaultSortAsc={initialSortDirection === 'asc'}
                  noDataComponent={customEmptyComponent ? <NoResultCustom/> : <NoResults />}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  onRowClicked={isLoading ? () => {} : handleRowClicked}
                  onRowMouseEnter={handleRowMouseEnter}
                  onRowMouseLeave={handleRowMouseLeave}
                  onSort={onChangeSort}
                  sortServer
                  pagination={pagination}
                  paginationServer
                  paginationDefaultPage={tableParams.page}
                  paginationTotalRows={recordsCount}
                  paginationPerPage={tableParams.pageSize}
                  paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                  progressComponent={<CustomLoader />}
                  customStyles={getStyleOverrides({ newStyling, rowStyling })}
                  striped={!isLoading && newStyling}
                  pointerOnHover={!isLoading}
                  sortIcon={<CustomSortIcon />}
                  highlightOnHover={true}
                  selectableRows={selectableRows}
                  selectableRowDisabled={selectableRowDisabled}
                  onSelectedRowsChange={onSelectedRowsChange}
                  clearSelectedRows={clearSelectedRows}
                  conditionalRowStyles={conditionalRowStyles}
                />
              );
            }}
          </TableColumnsContext.Consumer>
        </TableColumnsProvider>
      </TableWrapper>
    </>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export { Table };
