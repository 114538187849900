import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import {
  border,
  color,
  elevation,
  breakpoint,
  transition,
  typography,
  zIndex,
} from 'style';
import { ChevronLeft, ChevronRight } from '@gsc/icons/react';
import { H4 } from '../Heading';
import { Link } from '../Link';
import { Notification } from '../Notification';

interface FlyoutProps {
  buttons?: any[],
  className?: string,
  dataTest?: string,
  errorMessage?: string,
  filterCount?: number,
  handleClearFilters?: (...args: any[]) => any,
  handleClose?: (...args: any[]) => any,
  handleErrorClose?: (...args: any[]) => any,
  headerText?: string,
  isError?: boolean
  isOpen?: boolean,
};

const xTranslation = ({ isOpen }: FlyoutProps) => {
  if (isOpen) {
    return 'translate(0,0)';
  }
  return 'translate(100%,0)';
};

const Wrapper = styled.div<FlyoutProps>`
  ${typography.button};
  ${({ isOpen }) => isOpen && elevation['16']} transform: ${xTranslation};
  background-color: ${color.ui01};
  box-shadow: none;
  height: 100%;
  position: fixed;
  top: 0;
  transition: ${transition.primary('transform')};
  width: 100%;
  z-index: ${zIndex.alpha};

  ${breakpoint.lg`
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: fixed;
    right: 0
    top: 0;
    width:  40rem;
  `};
`;

const Overlay = styled.div<FlyoutProps>`
  backdrop-filter: blur(0.5rem);
  background-color: ${color.transparent.heavyInverse};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  pointer-events: ${({ isOpen }) => isOpen ? 'auto' : 'none'};
  position: fixed;
  top: 0;
  transition: ${transition.primary('opacity')};
  width: 100%;
  z-index: ${zIndex.bravo};
`;

const FlyoutHeader = styled.div`
  align-items: center;
  box-shadow: 0 0 0.4rem 0 rgba(0,0,0,0.24);
  display: flex;
  height: 5.6rem;
  padding: 1.6rem;
`;

const CloseIcon = window.innerWidth > 1024 ? ChevronRight : ChevronLeft;

const CloseFlyOut = styled(CloseIcon)`
  color: ${color.primary1};
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
`;

const HeaderText = styled(H4 as any)`
  ${typography.heading4};
  color: ${color.text1};
  margin-bottom: 0;
  margin-left: 3.2rem;

  ${breakpoint.lg`
    margin-left: 0.8rem;
  `};
`;

const ClearFilters = styled(Link)`
  ${typography.heading4};
  margin-left: auto;
`;

const FlyoutBody = styled.div`
  flex-grow: 1;
  height: calc(100% - 11.2rem);
  overflow-y: scroll;
  padding: 1.6rem;

  ${breakpoint.lg`
    border-left: ${border.normal(color.ui30)};
  `};
`;

const FlyoutFooter = styled.div`
  background-color: ${color.ui01};
  bottom: 0;
  box-shadow: 0 0 0.4rem 0 rgba(0,0,0,0.24);
  display: flex;
  height: 5.6rem;
  justify-content: center;
  left: 0;
  padding: 0.8rem 1.55rem;
  position: absolute;
  right: 0;

  > button {
    margin-right: 0;
  }
`;

const ErrorNotification = styled(Notification) <FlyoutProps>`
  ${elevation['16']};
  left: 3.2rem;
  position: absolute;
  top: 3.2rem;
  width: 33.6rem;
  z-index: 100;
`;

const Flyout: FC<FlyoutProps> = ({
  buttons,
  children,
  className,
  dataTest,
  errorMessage,
  filterCount,
  handleClearFilters,
  handleClose,
  handleErrorClose,
  headerText,
  isError,
  isOpen,
}) => (
  <Fragment>
    <Overlay isOpen={isOpen} onClick={handleClose} />
    <Wrapper className={className} isOpen={isOpen} data-test={dataTest}>
      {isError && errorMessage && (
        <ErrorNotification
          className={className && `${className}-error-notification`}
          status="negative"
          dataTest={dataTest && `${dataTest}-error-notification`}
          onClick={handleErrorClose}
        >
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </ErrorNotification>
      )}
      {headerText && (
        <FlyoutHeader>
          <CloseFlyOut onClick={handleClose} data-test={dataTest && `${dataTest}-close-button`} />
          <HeaderText dataTest={dataTest && `${dataTest}-header`}>{headerText}</HeaderText>
          {filterCount > 0 && (
            <ClearFilters
              component="button"
              onClick={handleClearFilters}
              data-test={dataTest && `${dataTest}-clear-button`}
            >
              {`CLEAR ALL (${filterCount})`}
            </ClearFilters>)}
        </FlyoutHeader>
      )}
      <FlyoutBody>
        {children}
      </FlyoutBody>
      {buttons && buttons.length > 0 && (
        <FlyoutFooter data-test={dataTest && `${dataTest}-footer`}>
          {buttons.map(button => React.cloneElement(button, { key: button.key }))}
        </FlyoutFooter>
      )}
    </Wrapper>
  </Fragment>
);

export { Flyout };