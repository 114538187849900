import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Downshift from 'downshift';
import { MoreMeatball, ChevronDown, ChevronUp } from '@gsc/icons/react';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '../Button';
import { DropdownMenu } from '../Dropdown';
import { ButtonMenuItem } from './ButtonMenuItem';
import { mixin } from 'style';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonMenuList = styled(DropdownMenu)`
  left: auto;
  max-height: none;
  min-width: 16rem;
  margin: 0;
  width: inherit;
  ${({ alignRight }) => (alignRight ? 'right: 0;' : 'left: 0')}
`;

const MeatballsIcon = styled(MoreMeatball)`
  ${mixin.size('2.4rem')}
  vertical-align: middle;
`;

class ButtonMenu extends Component {
  static propTypes = {
    alignRight: PropTypes.bool,
    isIconLeft: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonProps: PropTypes.object,
    className: PropTypes.string,
    dataTest: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.oneOf([
      'default',
      'neutral',
      'positive',
      'warning',
      'negative',
      'inverted',
    ]),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        dataTest: PropTypes.string,
        disabled: PropTypes.bool,
        status: PropTypes.oneOf([
          'default',
          'info',
          'positive',
          'warning',
          'negative',
        ]),
        children: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.node),
          PropTypes.node,
        ]).isRequired,
        onSelect: PropTypes.func,
      }),
    ),
    variant: PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
    ]),
  };

  static defaultProps = {
    alignRight: false,
    buttonText: undefined,
    className: undefined,
    dataTest: undefined,
    id: undefined,
    variant: 'primary',
    items: [],
    isIconLeft: false,
  };

  onSelect = ({ onSelect }) => {
    onSelect && onSelect();
  };

  render() {
    const {
      alignRight,
      buttonText,
      className,
      dataTest,
      disableArrow,
      icon,
      id,
      items,
      status,
      variant,
      isIconLeft,
      buttonProps,
    } = this.props;
    const itemLabel = item => item && item.children;
    let ButtonStyle = PrimaryButton;
    const useIcon = icon ? icon : <MeatballsIcon />;

    if (variant !== 'primary') {
      ButtonStyle = variant === 'secondary' ? SecondaryButton : TertiaryButton;
    }

    return (
      <Downshift onSelect={this.onSelect} itemToString={itemLabel}>
        {({
          getItemProps,
          getMenuProps,
          getToggleButtonProps,
          highlightedIndex,
          isOpen,
        }) => {
          const showMenu = !!items.length && isOpen;
          const useChevron = showMenu ? <ChevronUp /> : <ChevronDown />;

          return (
            <span id={id} className={className}>
              <Wrapper>
                <ButtonStyle
                  {...getToggleButtonProps({ isOpen: showMenu })}
                  isIconOnly={!buttonText}
                  isIconRight={!isIconLeft && buttonText ? true : false}
                  status={status}
                  data-test={dataTest}
                  {...buttonProps}
                >
                  {buttonText ? (
                    <Fragment>
                      {isIconLeft ? (
                        <Fragment>
                          {icon ? icon : (
                            <Fragment>
                              {disableArrow ? null : useChevron}
                            </Fragment>
                          )}
                          {buttonText}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {buttonText}
                          {icon ? icon : (
                            <Fragment>
                              {disableArrow ? null : useChevron}
                            </Fragment>
                          )}
                        </Fragment>
                      )}

                    </Fragment>
                  ) : (
                    useIcon
                  )}
                </ButtonStyle>
                <ButtonMenuList
                  alignRight={alignRight}
                  hidden={!showMenu}
                  {...getMenuProps()}
                >
                  {items.map((item, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <ButtonMenuItem
                      {...getItemProps({
                        key: index,
                        item,
                        status: item.status,
                        disabled: item.disabled,
                        active: highlightedIndex === index,
                        dataTest: item.dataTest,
                      })}
                    >
                      {item.children}
                    </ButtonMenuItem>
                  ))}
                </ButtonMenuList>
              </Wrapper>
            </span>
          );
        }}
      </Downshift>
    );
  }
}

export { ButtonMenu };
