import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { Loader } from '../Loader';
import { border, color, typography } from 'style';

const BaseStyled = styled.button`
  ${typography.button};
  align-items: center;
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui30)};
  cursor: pointer;
  display: inline-flex;
  height: 4rem;
  justify-content: center;
  white-space: nowrap;

  ${({ isIconOnly }) => (isIconOnly ? 'padding: 0 0.9rem;' : 'padding: 0 1.6rem;')}

  ${({ isIconOnly, isLarge }) => ((isIconOnly && isLarge) && 'padding: 0 1.3rem;')}

  ${({ isFullWidth }) => (isFullWidth && 'width: 100%;')}

  ${({ isInline }) => isInline && 'margin-right: 1.6rem;'}

  ${({ isSmall }) => isSmall && 'height: 3.2rem;'}

  ${({ isXSmall, isIconOnly }) => isXSmall && `
    height: 2.7rem;
    font-size: 1.3rem;
    ${isIconOnly ? 'padding: 0.5rem;' : 'padding: 0.4rem 0.8rem;'}
  `}
  
  ${({ isXXSmall, isIconOnly }) => isXXSmall && `
    height: 2.4rem;
    font-size: 1.3rem;
    ${isIconOnly ? 'padding: 0.5rem;' : 'padding: 0.2rem 0.8rem;'}
  `}

  ${({ isLarge }) => isLarge && `
    height: 4.8rem;
    font-size: 1.6rem;
  `}

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    ${({ isIconOnly, isLarge }) => (isIconOnly || isLarge ? 'width: 2rem;' : 'width: 1.6rem;')}

    ${({ isSmall }) => (isSmall && 'width: 1.2rem;')}

    ${({ isXSmall }) => (isXSmall && 'width: 1rem;')}

    ${({ isIconOnly }) => (!isIconOnly && 'margin-right: 1.2rem;')}

    ${({ isIconOnly, isXSmall }) => (!isIconOnly && isXSmall && 'margin: 0 0.6rem;')}

    ${({ isIconRight }) => (isIconRight && 'margin: 0 0 0 1.2rem;')}

    ${({ isIconRight, isIconOnly, isXSmall }) => isXSmall && !isIconOnly && `
      ${isIconRight ? 'margin: 0 0 0 0.6rem;' : 'margin: 0 0.6rem 0 0;'}
    `}
  }

  ${({ isLoading, isIconRight }) => (isLoading && !isIconRight && `
    div + svg {
      display: none;
    }
  `)}

  .button-loader {
    display: flex;
    margin-right: 1.2rem;

    ${({ isIconOnly }) => (isIconOnly && 'margin-right: 0;')}

    svg {
      margin: 0;
    }
  }
`;

const openNewTab = isNewTab => isNewTab ? { rel: 'noopener', target: '_blank' } : {};

const ChildrenWrapper = ({ children, dataTest, isLoading, isIconOnly, loadingText }) => {
  if (isLoading && isIconOnly) {
    return <Loader className="button-loader" dataTest={dataTest && `${dataTest}-loader`} />;
  } else if (isLoading) {
    return (
      <Fragment>
        <Loader
          className="button-loader"
          dataTest={dataTest && `${dataTest}-loader`}
        />
        {loadingText ? loadingText : children}
      </Fragment>
    );
  } else {
    return children;
  }
};

const BaseButton = React.forwardRef(({
  children,
  className,
  dataTest,
  disabled,
  href,
  isIconOnly,
  isLoading,
  isNewTab,
  loadingText,
  to,
  ...rest
}, ref) => (
  <BaseStyled
    /* eslint-disable-next-line no-nested-ternary */
    as={href ? 'a' : to ? Link : 'button'}
    data-test={dataTest}
    disabled={disabled || isLoading}
    className={classnames(className, { 'show-loader': isLoading })}
    ref={ref}
    href={href}
    isIconOnly={isIconOnly}
    isLoading={isLoading}
    to={to}
    type={href || to ? null : 'button'}
    {...openNewTab(isNewTab)}
    {...rest}
  >
    <ChildrenWrapper
      dataTest={dataTest}
      isIconOnly={isIconOnly}
      isLoading={isLoading}
      loadingText={loadingText}
    >
      {children}
    </ChildrenWrapper>
  </BaseStyled>
));

BaseButton.displayName = 'BaseButton';

BaseButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isIconOnly: PropTypes.bool,
  isIconRight: PropTypes.bool,
  isLarge: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNewTab: PropTypes.bool,
  isSmall: PropTypes.bool,
  loadingText: PropTypes.string,
  to: PropTypes.string,
};

BaseButton.defaultProps = {
  dataTest: undefined,
  className: '',
  disabled: false,
  href: undefined,
  isFullWidth: false,
  isIconOnly: false,
  isIconRight: false,
  isLarge: false,
  isLoading: false,
  isNewTab: false,
  isSmall: false,
  loadingText: undefined,
  to: undefined,
};

export { BaseButton };
