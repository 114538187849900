import React, { FC, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { border, color, transition, typography, zIndex } from 'style';

import { Label } from '../Label';
import { MarketXTooltip } from '../MarketXTooltip';

interface CheckboxProps {
  children?: ReactNode,
  checked?: boolean,
  className?: string,
  dataTest?: string,
  id?: string,
  name: string,
  onChange: (...args: any[]) => any,
  onClick?: (...args: any[]) => any,
  value: string,
  isSmall?: boolean,
  disabled?: boolean,
  disabledTooltipContent?: string,
};

interface DivProps {
  disabled: boolean;
  isSmall: boolean;
};


const StyledCheckbox = styled.div<DivProps>`
  vertical-align: middle;
  ${({ disabled }) => disabled && `
    cursor: default;
    * {
      cursor: default;
    }
  `}
  input:checked + label {
    &:before {
      width: 0.4rem;
      ${({ isSmall }) => (isSmall && 'width: 0.35rem;')}
    }
    &:after {
      width: 1rem;
      ${({ isSmall }) => (isSmall && 'width: 0.8rem;')}
    }
    .checkbox-background {
      background-color: ${color.mxDeepOcean};
      border-color: ${color.mxDeepOcean};
    }
  }
`;

const Input = styled.input`
  left: -9999rem;
  position: absolute;
`;

const CheckboxBox = styled.div<CheckboxProps>`
  background-color: ${color.ui01};
  border-radius: ${border.radius.small};
  border: ${border.normal(color.ui30)};
  display: inline-block;
  height: 1.6rem;
  margin-right: 0.8rem;
  position: relative;
  vertical-align: bottom;
  width: 1.6rem;
  ${({ disabled }) => disabled && `
    background-color: ${color.ui15};
    opacity: 0.3;
  `}
  ${({ isSmall }) => isSmall && `
    height: 1.4rem;
    width: 1.4rem;
  `}
`;

const CheckboxLabel = styled(Label)`
  ${typography.body}
  color: ${color.text1};
  line-height: 1.6rem;
  padding: 0;
  &:after {
    background: white;
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    transform-origin: left;
    transform: translate(0.6rem, 1.1rem) rotate(-45deg);
    ${({ isSmall }) => (isSmall && 'transform: translate(0.55rem, 1.15rem) rotate(-45deg);')}
    transition: ${transition.primary('width')};
    width: 0;
  }
  &:before {
    background: white;
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    transform-origin: left;
    transform: translate(0.35rem, 0.85rem) rotate(45deg);
    ${({ isSmall }) => (isSmall && 'transform: translate(0.35rem, 0.9rem) rotate(45deg);')}
    transition: ${transition.primary('width')};
    width: 0;
    z-index: ${zIndex.echo}
  }
`;

export type Ref = HTMLInputElement;

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef<Ref, CheckboxProps>(({
  checked,
  children,
  className,
  dataTest,
  id: _id,
  name,
  onChange,
  onClick,
  isSmall,
  disabled,
  disabledTooltipContent,
  value,
}, ref) => {
  const id = _id || value + name;

  const CheckboxContent = () => {
    return (
      <CheckboxLabel
        data-test={dataTest && `${dataTest}-label`}
        isSmall={isSmall}
        id={id}
      >
        <CheckboxBox
          className="checkbox-background"
          value={value}
          name={name}
          onChange={onChange}
          isSmall={isSmall}
          disabled={disabled}
        />
        {children}
      </CheckboxLabel>
    );
  };

  return (
    <StyledCheckbox
      className={className}
      data-test={dataTest}
      ref={ref}
      /* eslint-disable @typescript-eslint/no-empty-function */
      onClick={
        disabled
          ? (event) => {
            event.preventDefault();
          }
          : onClick
      }
      disabled={disabled}
      isSmall={isSmall}
    >
      <Input
        data-test={dataTest && `${dataTest}-input`}
        aria-checked={checked}
        aria-labelledby={id}
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={disabled ? () => {} : onChange}
        role="checkbox"
        type="checkbox"
        value={value}
      />
      {disabled && disabledTooltipContent ? (
        <MarketXTooltip
          dataTest={dataTest && `${dataTest}-tooltip`}
          hoverContent={<CheckboxContent />}
        >
          {disabledTooltipContent}
        </MarketXTooltip>
      ) : (
        <>{<CheckboxContent />}</>
      )}
    </StyledCheckbox>
  );
});

export { Checkbox };
