import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Close } from '@gsc/icons/react';

import { color } from 'style';
import { Content } from './Content';
import { Overlay } from './Overlay';

const CloseModal = styled(Close)`
  color: ${color.ui50};
  cursor: pointer;
  height: 1.6rem;
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
  width: 1.6rem;
`;

const PopupModal = ({ children, className, closeClick }) => (
  <Overlay>
    <Content
      closeClick={closeClick}
      className={className}
    >
      {children}
      <CloseModal onClick={closeClick} />
    </Content>
  </Overlay>
);

PopupModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeClick: PropTypes.func.isRequired,
};

PopupModal.defaultProps = {
  className: undefined,
};

export { PopupModal };
