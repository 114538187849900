/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Close } from '@gsc/icons/react';
import { border, color, form, mixin } from 'style';
import { LegacyPill } from '../Pill';

const RemoveIcon = styled(Close)`
  ${mixin.size('1rem')}
  fill: ${color.text600};
  vertical-align: baseline;
`;

const Container = styled.div`
  ${form.input.text()}
  position: relative;

  ${({ isSelectedItems }) =>
    isSelectedItems &&
    `
    padding: 0.4rem 0.8rem;
  `}

  ${({ focused }) => focused && `border: ${border.normal(color.mxDeepOcean)};`}

  ${({ hasError }) => hasError && form.input.text('negative')};

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${color.form.disabled.background};
    border: ${border.normal(color.form.disabled.border)};
    color: ${color.textHint};
    cursor: not-allowed;
  `}
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TextInput = styled.input`
  ${form.input.text()}
  border: 0;
  padding: 0;
  flex: 1;

  &:focus {
    border: 0;
  }

  &:disabled {
    border: 0;
  }
`;

export const PillWrapper = styled(LegacyPill)`
  display: inline-block;
  margin: 0.2rem;
`;

export const ClickableSection = styled.span`
  cursor: pointer;
  padding-left: 0.6rem;
`;

class TypeaheadInput extends Component {
  state = { focused: false };

  focus = () => {
    const { onFocus } = this.props;
    this.setState({ focused: true }, onFocus);
  };

  unFocus = () => {
    this.setState({ focused: false });
  };

  render() {
    const {
      dataTest,
      disabled,
      inputProps,
      itemLabel,
      onKeyUp,
      onRemove,
      placeholder,
      selectedItems,
      hasError,
    } = this.props;
    const { focused } = this.state;

    return (
      <Container
        focused={focused}
        disabled={disabled}
        isSelectedItems={selectedItems.length > 0}
        hasError={hasError}
      >
        <FlexWrapper>
          {selectedItems.map(item => (
            <PillWrapper key={itemLabel(item)}>
              <span>{itemLabel(item)}</span>
              <ClickableSection
                data-test={`${dataTest}-remove`}
                onClick={() => !disabled && onRemove(item)}
              >
                <RemoveIcon />
              </ClickableSection>
            </PillWrapper>
          ))}
          <TextInput
            {...inputProps}
            disabled={disabled}
            placeholder={selectedItems.length ? '' : placeholder}
            onKeyUp={onKeyUp}
            onFocus={this.focus}
            onBlur={this.unFocus}
            data-test={dataTest}
          />
        </FlexWrapper>
      </Container>
    );
  }
}

TypeaheadInput.propTypes = {
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  itemLabel: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onRemove: PropTypes.func,
  placeholder: PropTypes.string,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ),
  inputClassName: PropTypes.string,
};

TypeaheadInput.defaultProps = {
  dataTest: undefined,
  disabled: false,
  inputProps: {},
  inputValue: [],
  itemLabel: item => item,
  onFocus: () => {},
  onKeyUp: () => {},
  placeholder: 'Enter one or more values',
  selectedItems: [],
};

export { TypeaheadInput };
