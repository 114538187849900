import { breakpoint } from './breakpoint';
import { border } from './border';
import { color } from './color';
import { typography } from './typography';
import { bootstrapOverrides } from './bootstrapOverrides';

const formFieldBase = (status = 'default') => `
  ${typography.form}
  ${bootstrapOverrides.input}

  background-color: ${color.form.default.background};
  border-radius: ${border.radius.normal};
  border: ${
  status && status === 'negative'
    ? border.normal(color.form.negative.border)
    : border.normal(color.form.default.border)
};
  box-shadow: none;
  color: ${color.text600};
  font-size: 1.4rem;
  height: auto;
  line-height: 3.8rem;
  margin: 0;
  padding: 0 1.6rem;
  width: 100%;

  &::placeholder {
    color: ${color.textHint};
  }

  &:focus {
    border: ${border.normal(color.form.focus.border)};
    box-shadow: none;
  }

  &:disabled {
    background-color: ${color.form.disabled.background};
    border: ${border.normal(color.form.disabled.border)};
    color: ${color.textHint};
    cursor: not-allowed;
  }

  ${breakpoint.lg`
    & {
      font-size: 1.4rem;
    }
  `}
`;

export const form = {
  input: {
    text: (status = 'default') => `
      ${formFieldBase(status)};
    `,
  },
  textarea: (status = 'default') => `
    ${formFieldBase(status)};
    min-height: 8rem;
  `,
};
