import React, { FC } from 'react';
import styled from 'styled-components';
import { color, typography, bootstrapOverrides } from 'style';
import { Link } from '../Link';
import { NavLink } from 'react-router-dom';

const BreadcrumbsWrapper = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    ${typography.body}
    color: ${color.text600};
    display: inline-block;
    margin-right: 1rem;
    position: relative;

    &:after {
      ${bootstrapOverrides.breadcrumbs}
      content: ' / ';
      position: absolute;
      right: -0.8rem;
      top: 0;
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
`;

type BreadcrumbsProps = {
  breadcrumbs: {
    url?: string;
    text: string;
  }[];
  withRouter?: boolean;
  dataTest?: string;
};

const Breadcrumb = styled(Link)`
  ${typography.body};
`;

const CurrentPage = ({ children, ...rest }) => (
  <span {...rest}>{children}</span>
);

const BreadcrumbsList = ({ breadcrumbs, dataTest, withRouter }) => {
  return breadcrumbs.map(({ url, text }, index) => {
    if (withRouter) {
      return (
        <li key={text + index}>
          <Breadcrumb
            aria-current={index === breadcrumbs.length - 1 ? 'page' : undefined}
            data-test={dataTest && `${dataTest}-link-${index}`}
            as={index === breadcrumbs.length - 1 ? CurrentPage : ({ children, ...rest }) => <Link component={NavLink} to={url} {...rest}>{children}</Link> }
            href={url}
            to={url}
          >
            {text}
          </Breadcrumb>
        </li>
      );
    } else {
      return (
        <li key={text + index}>
          <Breadcrumb
            aria-current={index === breadcrumbs.length - 1 ? 'page' : undefined}
            data-test={dataTest && `${dataTest}-link-${index}`}
            as={index === breadcrumbs.length - 1 ? CurrentPage : Link}
            href={url}
          >
            {text}
          </Breadcrumb>
        </li>
      );
    }
  });
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
  dataTest,
  withRouter,
}) => (
  <nav aria-label="breadcrumb" data-test={dataTest}>
    <BreadcrumbsWrapper>
      <BreadcrumbsList breadcrumbs={breadcrumbs} dataTest={dataTest} withRouter={withRouter} />
    </BreadcrumbsWrapper>
  </nav>
);

export { Breadcrumbs };
