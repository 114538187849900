export const bootstrapOverrides = {
  breadcrumbs: `
    text-shadow: none;
  `,
  notifications: `
    p {
      margin-bottom: 0;
    }
  `,
  toggleLabel: `
    margin-bottom: 0;
  `,
  textInline: `
    box-shadow: none;
  `,
  input: `
    box-shadow: none;
    transition: none;

    &:focus {
      box-shadow: none;
    }
  `,

  errorWrapper: `
    span {
      margin-top: 0 !important;
    }
  `,
};
