import React, { FC } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { color, typography } from 'style';
import { Label } from '../Label';

const Input = styled.input`
  left: -999.9rem;
  position: absolute;
`;

const RadioLabel = styled(Label)`
  ${typography.body}
  color: ${color.text1};
  line-height: 1.6rem;
  padding-left: 2.4rem;

  &:before {
    background-color: ${color.ui01};
    border-radius: 100%;
    border: 0.1rem solid ${color.ui15};
    content: '';
    height: 1.6rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.6rem;
  }

  &:after {
    background-color: ${color.ui01};
    border-radius: 100%;
    content: '';
    height: 0.67rem;
    left: 0.45rem;
    position: absolute;
    top: 0.45rem;
    width: 0.67rem;
  }

  &.checked {
    &:before {
      background-color: ${color.mxDeepOcean};
      border: 0.1rem solid ${color.mxDeepOcean};
    }

    &:after {
      background-color: ${color.ui01};
      border-radius: 100%;
    }
  }

  &.disabled {
    cursor: not-allowed;

    &:before {
      background-color: ${color.ui05};
      border: 0.1rem solid ${color.ui15};
    }

    &:after {
      background-color: ${color.ui05};
    }

    &.checked:after {
      background-color: ${color.ui15};
    }
  }
`;

interface RadioProps {
  checked?: boolean,
  className?: string,
  dataTest?: string,
  disabled?: boolean
  id?: string,
  name: string,
  onChange: (...args: any[]) => any,
  value: string,
};

const Radio: FC<RadioProps> = ({
  checked,
  children,
  className,
  dataTest,
  disabled,
  id: _id,
  name,
  onChange,
  value,
}) => {
  const id = _id || value + name;

  return (
    <div className={className} data-test={dataTest}>
      <Input
        aria-checked={checked}
        aria-labelledby={id}
        checked={checked}
        data-test={dataTest && `${dataTest}-input`}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        role="radio"
        type="radio"
        value={value}
      />
      <RadioLabel
        data-test={dataTest && `${dataTest}-label`}
        className={classnames({ checked, disabled })}
        id={id}
        isInline
      >
        {children}
      </RadioLabel>
    </div>
  );
};

export { Radio };
