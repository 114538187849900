import React from 'react';
import imageFile from './routing.svg';

export default {
  /* 👇 The title prop is optional.
  * See https://storybook.js.org/docs/react/configure/overview#configure-story-loading
  * to learn how to generate automatic titles
  */
  title: 'img',
};

const image = {
  src: imageFile,
  alt: 'routing',
};

export const RoutingImage = () => <img src={image.src} alt={image.alt} />;