import { color } from './color';

export const border = {
  normal: (borderColor = color.ui50) => `${borderColor} 0.1rem solid`,
  heavy: (borderColor = color.ui50) => `${borderColor} 0.4rem solid`,

  radius: {
    normal: '0.4rem',
    small: '0.2rem',
  },
};
