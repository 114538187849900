import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { border, color, elevation } from 'style';
import { OutsideClick } from '../OutsideClick';

const ContentStyled = styled(OutsideClick)`
  ${elevation['24']}
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui30)};
  max-width: 45rem;
  min-height: 20rem;
  min-width: 40.8rem;
  padding: 3.2rem 2.4rem;
  position: relative;
`;

const Content = ({ children, className, closeClick }) => (
  <ContentStyled
    className={className}
    onOutsideClick={closeClick}
  >
    {children}
  </ContentStyled>
);

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeClick: PropTypes.func.isRequired,
};

Content.defaultProps = {
  className: undefined,
};

export { Content };
