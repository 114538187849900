import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { border, color, mixin, typography, bootstrapOverrides } from 'style';
import { Close, TipCircle, WarningCircle, SuccessCircle, ErrorCircle } from '@gsc/icons/react';

const iconMap = {
  default: <TipCircle className="status-icon" />,
  info: <TipCircle className="status-icon" />,
  positive: <SuccessCircle className="status-icon" />,
  warning: <WarningCircle className="status-icon" />,
  negative: <ErrorCircle className="status-icon" />,
};

const Wrapper = styled.div`
  ${bootstrapOverrides.notifications}
  ${typography.body}
  border-radius: ${border.radius.normal};
  display: flex;
  padding: 1.6rem;

  .status-icon {
    height: 2rem;
    width: 2rem;
    margin-right: 0.8rem;
  }

  ${({ status }) => `
    border: ${border.normal(color.notification[status].border)};
    color: ${color.notification[status].text};
    background-color: ${color.notification[status].background};
  `}
`;

const Message = styled.p`
  flex: 1;
`;

const CloseIcon = styled(Close)`
  ${mixin.size('1.6rem')}
  position: relative;
  top: 0.2rem;
  cursor: pointer;
`;

const Notification = ({ children, className, onClick, status, dataTest }) => (
  <Wrapper className={className} status={status} data-test={dataTest}>
    {iconMap[status]}
    <Message data-test={dataTest && `${dataTest}-message`}>
      {children}
    </Message>
    {onClick !== undefined &&
      <CloseIcon
        data-test={dataTest && `${dataTest}-close`}
        onClick={onClick}
      />
    }
  </Wrapper>
);

Notification.propTypes = {
  dataTest: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.oneOf([
    'default',
    'info',
    'positive',
    'warning',
    'negative',
  ]),
};

Notification.defaultProps = {
  dataTest: undefined,
  onClick: undefined,
  status: 'default',
};

export { Notification };
