import styled from 'styled-components';
import PropTypes from 'prop-types';

import { border, color } from 'style';
import { BaseButton } from './BaseButton';

const TertiaryButton = styled(BaseButton)`
  background-color: transparent;
  border: ${border.normal('transparent')};
  color: ${({ status }) => color.button.secondary[status].text};

  &:focus {
    background-color: ${color.ui05};
    border-color: ${color.ui05};
    box-shadow: 0 0 0 0.2rem ${color.ui15};
  }

  &:active {
    background-color: ${color.ui15};
    border-color: ${color.ui15};
    box-shadow: none;
  }

  &:not(.show-loader) {
    &:hover {
      background-color: ${color.ui10};
      border-color: ${color.ui10};
      color: ${({ status }) => color.button.secondary[status].text};
    }

    &:disabled {
      background-color: ${color.button.secondary.disabled.default};
      border: ${border.normal(color.button.secondary.disabled.default)};
      color: ${color.button.secondary.disabled.text};
    }
  }
`;

TertiaryButton.propTypes = {
  status: PropTypes.oneOf([
    'default',
    'neutral',
    'positive',
    'warning',
    'negative',
    'inverted',
  ]),
};

TertiaryButton.defaultProps = {
  status: 'default',
};

TertiaryButton.displayName = 'TertiaryButton';

export { TertiaryButton };
