import { color } from './color';

export const mixin = {
  size: size => `
    height: ${size};
    width: ${size};
  `,
  centerVertically: () => `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `,
  center: () => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  disabledControl: () => `
    color: ${color.textWhite};
    background-color: ${color.ui15};
    border-color: ${color.ui15};
    cursor: not-allowed;
  `,
  edges: distance => `
    top: ${distance};
    bottom: ${distance};
    left: ${distance};
    right: ${distance};
  `,
  upTriangle: (size, color) => `
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: ${size} solid transparent;
    border-right: ${size} solid transparent;
    border-bottom: ${size} solid ${color};
  `,
  downTriangle: (size, color) => `
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: ${size} solid transparent;
    border-right: ${size} solid transparent;
    border-top: ${size} solid ${color};
  `,
};
