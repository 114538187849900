import React, { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Skeleton } from '../SkeletonLoader';
import styled from 'styled-components';
import { color } from 'style';
import { TableCellTooltip } from './TableCellTooltip';

const StyledSkeletonLoader = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  align-self: center;
  justify-self: center;
  height: ${props => props.newStyling ? '1.6rem;' : '2rem;'};
  width: 15rem;
`;

const rowStyling = {
  style: {
    minHeight: '32px',
  },
  highlightOnHoverStyle: {
    backgroundColor: color.ui01,
    boxShadow: `0 0.3rem 0.4rem ${color.ui16}`,
    position: 'relative',
    outlineStyle: 'none',
    outlineWidth: 0,
    borderBottomColor: `${color.ui16}`,
  },
  stripedStyle: {
    backgroundColor: color.ui11,
  },
};

const StyleContainer = styled.div`
  .column__sticky {
    position: sticky;
    z-index: 1;
    background-color: ${rowStyling.highlightOnHoverStyle.backgroundColor};
  }
  .column__sticky:after {
    opacity: 0;
    transition: opacity ease-out 0.2s;
    border-right: 0.1rem solid ${color.ui16};
    box-shadow: 0.3rem 0 0.3rem 0 ${color.ui16};
    box-sizing: border-box;
    content: '';
    position: absolute;
    height: 120%;
    right: 0;
    top: -0.2rem;
    width: 0.5rem;
  }
  .column__sticky-edge:after {
    opacity: 1;
  }

  .rdt_TableBody {
    .rdt_TableRow:nth-child(odd) {
      .column__sticky {
        background-color: ${props => props.isLoading ? rowStyling.stripedStyle.backgroundColor : 'inherit'};
      }
    }

    .rdt_TableRow:hover {
      z-index: 2;
      .column__sticky {
        background-color: ${rowStyling.highlightOnHoverStyle.backgroundColor};
        transition-property: inherit;
        transition-duration: inherit;
      }
    }
  }
`;

const TableColumnsContext = React.createContext();

export const TableColumnsProvider = ({
  className,
  isLoading,
  tableColumns,
  children,
  newStyling,
  dataTest,
  selectableRows,
} = []) => {
  const [showBorder, setShowBorder] = useState(false);
  const [columnPositionMapping, setColumnPositionMapping] = useState({});
  const removeFixedColumnBorderStyling = () => {
    setShowBorder(false);
    const columnElementWithBorder = document.querySelector('.rdt_TableCol.column__sticky-edge');
    if (columnElementWithBorder) {
      columnElementWithBorder.classList.remove('column__sticky-edge');
    }
  };
  const debouncedShowBorderAnimation = useMemo(
    () => debounce(removeFixedColumnBorderStyling, 1000)
    , []);

  useEffect(() => {
    if (isLoading) return;

    const onScroll = () => {
      setShowBorder(true);
      debouncedShowBorderAnimation();
    };
    document.getElementsByClassName(className)[0]?.addEventListener('scroll', onScroll);
  }, [isLoading]);

  const tableColSizer = (entries) => {
    setShowBorder(false);
    const tableHead = entries[0]?.target;
    if (!tableHead) return;

    setTimeout(() => {
      const tableCols = tableHead.querySelectorAll('.rdt_TableCol');
      const columnPositions = {};
      let offset = 0;
      tableCols.forEach((col) => {
        columnPositions[col.getAttribute('data-column-id')] = {
          left: offset,
          width: col.clientWidth,
        };
        offset += col.clientWidth;
        col.style.left = offset;
        col.style.width = col.clientWidth;
        removeFixedColumnBorderStyling();
      });

      setColumnPositionMapping(columnPositions);
    }, 0);
  };

  useEffect(() => {
    if (isLoading) return;

    const targetNode = document.querySelector('.rdt_TableHead');
    if (!targetNode) return;

    const resizeObserver = new ResizeObserver(tableColSizer);
    resizeObserver.observe(targetNode);

    return () => {
      resizeObserver.unobserve(targetNode);
    };
  }, [isLoading]);

  const lastFixedColumn = tableColumns.filter((c) => c?.sticky).pop();
  const TableColumns = tableColumns.map((column, i) => {
    const colPositionInfo = columnPositionMapping[column.id];
    if (column.sticky) {
      const columnElement = document.querySelector(`.rdt_TableCol[data-column-id="${column.id}"]`);
      const columnPositionInfo = columnPositionMapping[column.id];
      if (columnPositionInfo && columnElement) {
        columnElement.classList.add('column__sticky');
        columnElement.style.left = `${columnPositionInfo.left}px`;
        if (showBorder && column.id === lastFixedColumn?.id) {
          columnElement.classList.add('column__sticky-edge');
        } else {
          columnElement.classList.remove('column__sticky-edge');
        }
      }
      if (selectableRows) {
        const rowSelectionCheckboxes = document.querySelectorAll('[data-gsc-cell-id="gospotcheck-row-select-checkbox"]');
        if (rowSelectionCheckboxes.length > 0) {
          rowSelectionCheckboxes.forEach((rowSelectionCheckbox) => {
            const rowSelectionCell = rowSelectionCheckbox.closest('.rdt_TableCol, .rdt_TableCell');
            rowSelectionCell.classList.add('column__sticky');
            rowSelectionCell.style.left = '0px';
          });
        }
      }
    }

    const checkColSelectorOverflow = (column, row) => {
      const content = column.selector(row);
      if (column.overflowTooltip || column.overflowTooltip === null || column.overflowTooltip === undefined) {
        return (
          <TableCellTooltip>
            {content}
          </TableCellTooltip>
        );
      } else {
        return content;
      }
    };

    return {
      ... column,
      /* eslint-disable-next-line react/display-name */
      ...(column.sticky && { conditionalCellStyles: [
        {
          when: _row => true,
          classNames: ['column__sticky'],
          style: {
            left: `${colPositionInfo?.left}px`,
          },
        },
        {
          when: _row => showBorder && column.id === lastFixedColumn?.id,
          classNames: ['column__sticky-edge'],
          style: {
            left: `${colPositionInfo?.left}px`,
          },
        },
      ] }),
      ...(newStyling && column.actionsCell && { conditionalCellStyles: [
        {
          when: _row => true,
          style: {
            padding: '4px 8px',
          },
        },
      ] }),
      /* eslint-disable-next-line react/display-name */
      ...(column.selector && { selector: row => isLoading ? <StyledSkeletonLoader displayName="cell-loader" dataTest={`${dataTest}-cell-loader`} newStyling={newStyling}><Skeleton height="100%" /></StyledSkeletonLoader> : checkColSelectorOverflow(column, row) }),
      /* eslint-disable-next-line react/display-name */
      ...(column.cell && { cell: (row, index, c) => isLoading ? <StyledSkeletonLoader displayName="cell-loader" dataTest={`${dataTest}-cell-loader`} newStyling={newStyling}><Skeleton height="100%" /></StyledSkeletonLoader> : column.cell(row, index, c) }),
    };
  });

  const value = {
    TableColumns,
    rowStyling,
  };

  return (
    <TableColumnsContext.Provider value={value}>
      <StyleContainer rowStyling={rowStyling}>
        {children}
      </StyleContainer>
    </TableColumnsContext.Provider>
  );
};
export { TableColumnsContext };
export const CompanyPropertiesConsumer = TableColumnsContext.Consumer;
