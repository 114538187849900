export const font = {
  family: '\'Helvetica Neue\', Helvetica, \'Roboto\', sans-serif',
  weight: {
    light: 300,
    normal: 400,
    medium: 500,
  },
};

export const typography = {
  display: `
    font-family: ${font.family};
    font-size: 4rem;
    font-weight: ${font.weight.medium};
    line-height: 4.8rem;
  `,

  heading1: `
    font-family: ${font.family};
    font-size: 3.2rem;
    font-weight: ${font.weight.normal};
    line-height: 4rem;
  `,

  heading2: `
    font-family: ${font.family};
    font-size: 2.4rem;
    font-weight: ${font.weight.medium};
    line-height: 3.2rem;
  `,

  heading3: `
    font-family: ${font.family};
    font-size: 2rem;
    font-weight: ${font.weight.medium};
    line-height: 2.4rem;
  `,

  heading4: `
    font-family: ${font.family};
    font-size: 1.6rem;
    font-weight: ${font.weight.medium};
    line-height: 2rem;
  `,

  heading5: `
    font-family: ${font.family};
    font-size: 1.4rem;
    font-weight: ${font.weight.medium};
    line-height: 1.8rem;
  `,

  heading6: `
    font-family: ${font.family};
    font-size: 1.2rem;
    font-weight: ${font.weight.medium};
    line-height: 1.6rem;
  `,

  bodyLarge: `
    font-family: ${font.family};
    font-size: 1.6rem;
    font-weight: ${font.weight.normal};
    line-height: 2.4rem;
  `,

  body: `
    font-family: ${font.family};
    font-size: 1.4rem;
    font-weight: ${font.weight.normal};
    line-height: 2rem;
  `,

  bodySmall: `
    font-family: ${font.family};
    font-size: 1.2rem;
    font-weight: ${font.weight.normal};
    line-height: 1.6rem;
  `,

  navigation: `
    font-family: ${font.family};
    font-weight: ${font.weight.normal};
  `,

  form: `
    font-family: ${font.family};
    font-weight: ${font.weight.normal};
  `,

  label:`
    font-family: ${font.family};
    font-weight: ${font.weight.medium};
  `,

  button: `
    font-family: ${font.family};
    font-size: 1.4rem;
    font-weight: ${font.weight.medium};
  `,
};
