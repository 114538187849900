import React from 'react';
import PropTypes from 'prop-types';
import { Content } from './Content';
import { Overlay } from './Overlay';

const BaseModal = ({ children, className, closeClick }) => (
  <Overlay className={className}>
    <Content closeClick={closeClick}>
      {children}
    </Content>
  </Overlay>
);

BaseModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeClick: PropTypes.func.isRequired,
};

BaseModal.defaultProps = {
  className: undefined,
};

export { BaseModal };
