import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, mixin, zIndex } from 'style';

const OverlayBackground = styled.div`
  ${mixin.edges(0)};
  align-items: center;
  background-color: ${color.transparent.heavyInverse};
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: ${zIndex.alpha};
`;

const Overlay = ({ children, className }) => (
  <OverlayBackground className={className}>
    {children}
  </OverlayBackground>
);

Overlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Overlay.defaultProps = {
  className: undefined,
};

export { Overlay };
