import { useEffect, useRef } from 'react';

const useEffectOnUpdate = (fn, dependencies) => {
  const initialValue = useRef(true);

  useEffect(() => {
    if (!initialValue.current) {
      return fn();
    } else {
      initialValue.current = false;
    }
  }, [...dependencies]);
};

export default useEffectOnUpdate;
