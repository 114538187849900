import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '../Input';
import { Label } from '../Label';
import { Search, Close } from '@gsc/icons/react';
import debounce from 'lodash/debounce';
import { color } from 'style';
import useEffectOnUpdate from '../helpers/useEffectOnUpdate';

const TextInput = styled(Input)`
  margin: 0;
  width: 100%;

  input {
    color: ${color.text600};
  }
`;

const SearchLabel = styled(Label)`
  position: relative;
  padding: 0;
`;

const Form = styled.form`
  display: inline-block;
  min-width: 30rem;
`;

const SearchIcon = styled(Search)`
  color: ${color.ui50};
  cursor: auto;
  display: inline-block;
  position: absolute;
  right: 0.8rem;
  top: 1.2rem;
  height: 1.6rem;
  width: 1.6rem;
`;

const CloseIcon = styled(Close)`
  color: ${color.ui50};
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 0.8rem;
  top: 1.2rem;
  height: 1.6rem;
  width: 1.6rem;
`;

const EMPTY_SEARCH_VALUE = '';

const SearchForm = ({ value, onSubmit, className, dataTest, placeholderText, name }) => {
  const [searchValue, setSearchValue] = useState(value || EMPTY_SEARCH_VALUE);
  const debouncedChangeHandler = useMemo(() => debounce(onSubmit, 400), []);

  useEffectOnUpdate(() => {
    if (searchValue && searchValue.length > 0) {
      debouncedChangeHandler(searchValue);
    } else {
      onSubmit(EMPTY_SEARCH_VALUE);
    }

    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [searchValue]);

  const onChange = (event) => {
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const onValueClear = () => {
    setSearchValue(EMPTY_SEARCH_VALUE);
  };

  const submitForm = (event) => {
    event.preventDefault();

    onSubmit(searchValue);
  };

  return (
    <Form
      data-test={dataTest && `${dataTest}-form`}
      className={className || 'search-form'}
      onSubmit={submitForm}
    >
      <SearchLabel id={name}>
        <TextInput
          data-test={dataTest && `${dataTest}-input`}
          id={name}
          name={name}
          onChange={onChange}
          placeholder={placeholderText}
          value={searchValue}
          isErrorable={false}
        />
        {searchValue && searchValue.length > 0 ? <CloseIcon data-test="icon__clear-search" onClick={onValueClear} /> : <SearchIcon/>}
      </SearchLabel>
    </Form>
  );
};

SearchForm.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholderText: PropTypes.string,
  value: PropTypes.string,
};

SearchForm.defaultProps = {
  className: undefined,
  dataTest: undefined,
  onSubmit: () => {},
  placeholderText: 'Search',
};

export { SearchForm };