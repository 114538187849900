import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropdownMenuItem } from '../Dropdown';

const ButtonMenuItem = styled(DropdownMenuItem)`
  white-space: nowrap;
`;

ButtonMenuItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  status: PropTypes.oneOf([
    'default',
    'info',
    'positive',
    'warning',
    'negative',
  ]),
};

ButtonMenuItem.defaultProps = {
  active: false,
  disabled: false,
  status: 'default',
};

export { ButtonMenuItem };
