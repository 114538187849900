/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from '../Label';
import { Close, SuccessCheckmark } from '@gsc/icons/react';

import { color, transition, bootstrapOverrides } from 'style';

const StyledToggle = styled.div`
  input:checked + label {
    .toggle-background {
      background-color: ${color.mxDeepOcean};
      border-radius: 1.3rem;

      &:before {
        transform: translate3d(2.4rem, 0, 0);
      }
    }
  }

  input:disabled + label {
    .toggle-background {
      cursor: not-allowed;
    }

    .toggle-background:before {
    background-color: ${color.ui10};

    }
    .toggle-icon {
      display: none;
    }
  }
`;

const Input = styled.input`
  left: -9999rem;
  position: absolute;
`;

const ToggleLabel = styled(Label)`
  align-items: center;
  display: flex;
  padding: 0;
`;

const ToggleLabelInner = styled.div`
  background-color: ${color.ui20};
  border-radius: 1.3rem;
  display: inline-block;
  height: 2.4rem;
  padding: 0;
  position: relative;
  width: 4.8rem;
`;

const ToggleLabelCopy = styled.p`
  ${bootstrapOverrides.toggleLabel}
  color: ${color.ui90};
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-left: 1.2rem;
`;

const CloseIcon = styled(Close)`
  height: 1.2rem;
  position: absolute;
  right: 0.8rem;
  top: 0.6rem;
`;

const SuccessIcon = styled(SuccessCheckmark)`
  color: white;
  height: 1.2rem;
  left: 0.6rem;
  position: absolute;
  top: 0.6rem;
`;

const ToggleCircle = styled.div`
  height: 2.4rem;

  &:before {
    background-color: white;
    border-radius: 50%;
    content: '';
    cursor: pointer;
    height: 2.2rem;
    left: 0.1rem;
    position: absolute;
    top: 0.1rem;
    transition: ${transition.primary('transform')};
    width: 2.2rem;
    z-index: 1;
  }
`;

const Toggle = ({
  checked,
  className,
  disabled,
  hasLabel,
  id: _id,
  name,
  onChange,
  onClick,
  value,
}) => {
  const id = _id || value + name;
  const toggleState = checked ? 'On' : 'Off';
  const tooltip = !hasLabel ? toggleState : null;

  return (
    <StyledToggle
      onClick={onClick}
      className={className}
    >
      <Input
        type="checkbox"
        id={id}
        value={value}
        name={name}
        aria-labelledby={id}
        role="checkbox"
        checked={checked}
        aria-checked={checked}
        onChange={onChange}
        disabled={disabled}
        data-test={`toggle-switch-${id}`}
      />

      <ToggleLabel id={id}>
        <ToggleLabelInner title={tooltip}>
          <SuccessIcon className="toggle-icon"/>
          <ToggleCircle className="toggle-background" />
          <CloseIcon className="toggle-icon"/>
        </ToggleLabelInner>
        {hasLabel && !disabled && (
          <ToggleLabelCopy>
            {toggleState}
          </ToggleLabelCopy>
        )}
      </ToggleLabel>
    </StyledToggle>
  );
};

Toggle.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  hasLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.string.isRequired,
};

Toggle.defaultProps = {
  id: '',
  checked: false,
  children: [],
  className: undefined,
  hasLabel: true,
  onClick: () => {},
};

export { Toggle };
