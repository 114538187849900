import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React, {
  FC,
  useState,
} from 'react';
import { DayPickerRangeController as RangePicker } from 'react-dates';
import classnames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import calendarIconUrl from '@gsc/icons/svg/calendar-standard.svg';

import {
  border,
  breakpoint,
  color,
  elevation,
} from 'style';
import { SecondaryButton } from '../Button';
import { Input } from '../Input';
import { useWindowDimensions } from '../utils';
import {
  DateRangePickerStyleOverrides,
  PrevArrow,
  NextArrow,
} from './components';

interface DateRangePickerProps {
  dataTest: string;
  enableOutsideDays?: boolean;
  initialEndDate?: moment.Moment;
  initialStartDate?: moment.Moment;
  isOutsideRange?(day: string): any;
  onChange({ startDate, endDate }): any;
}

interface SecondaryButtonProps {
  className?: string;
  children: string;
  dataTest: string;
  id?: string;
  onClick(any): any;
  status?: string;
}

interface StyledInputProps {
  className?: string;
  dataTest: string;
  label: string;
  name: string;
  onChange?({ startDate, endDate }): any;
  placeholder?: string;
  value: string;
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const RangePickerContainer = styled.div`
  ${elevation['12']}
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui15)};
  display: flex;
  flex-direction: column-reverse;
  padding: 1.6rem 1.2rem;
  width: 32.7rem;

  ${breakpoint.lg`
    flex-direction: row;
    padding: 3.2rem 2.4rem 2.8rem;
    width: 46rem;
  `};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 8.4rem;
  justify-content: space-between;

  button:nth-child(-n+4) {
    margin-right: 0.4rem;
  }

  ${breakpoint.lg`
    flex-flow: column nowrap;
    height: auto;

    button:nth-child(-n+4) {
      margin-right: 0;
    }

    button:last-child {
      margin-bottom: 0;
    }
  `};
`;

const StyledSecondaryButton = styled(SecondaryButton as any)<SecondaryButtonProps>`
  padding: 0 1.4rem;

  &.selected {
    border-color: ${color.primary1};
    color: ${color.primary1};
  }

  ${breakpoint.lg`
    margin-bottom: 1.6rem;
    padding: 0 1.6rem;
  `}
`;

const CalendarWrapper = styled.div`
  ${breakpoint.lg`
    margin-left: 2.4rem;
  `};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .start-input {
    margin-right: 0.7rem;

    ${breakpoint.lg`
      margin-right: 1.5rem;
    `};
  }
`;

const StyledInput = styled(Input)<StyledInputProps>`
  > input {
    background: transparent url(${calendarIconUrl}) no-repeat 0.8rem center;
    background-size: 1.2rem;
    padding-left: 2.8rem;
    padding-right: 1.2rem;
    width: 14.8rem;

    ${breakpoint.lg`
      width: 13rem;
    `};
  }

  > div {
    min-height: 1.4rem;
  }
`;

const DateRangePicker: FC<DateRangePickerProps> = ({
  dataTest,
  enableOutsideDays,
  initialEndDate = null,
  initialStartDate = null,
  isOutsideRange,
  onChange,
}) => {
  const isMobile = useWindowDimensions().width < 768;
  const isLast7Days = moment().subtract(6, 'days');
  const isLast30Days = moment().subtract(29, 'days');
  const isThisMonth = moment().date(1);
  const isEndOfLastMonth = moment().subtract(1, 'month').endOf('month');
  const isStartOfLastMonth = moment().subtract(1, 'month').startOf('month');
  const isThisYear = moment().dayOfYear(1);

  const [startDate, setStartDate] = useState(initialStartDate);
  const [startDateString, setStartDateString] = useState(initialStartDate ? initialStartDate.format('L') : '');
  const [endDate, setEndDate] = useState(initialEndDate);
  const [endDateString, setEndDateString] = useState(initialEndDate ? initialEndDate.format('L') : '');
  const [focusedInput, setFocusedInput] = useState('startDate');

  const [selectedShortcut, setSelectedShortcut] = useState('');

  const setActiveShortcut = (id: string) => setSelectedShortcut(id);
  const resetActiveShortcut = () => setSelectedShortcut('');

  const handleDatesChange = ({ startDate, endDate }, e?: HTMLInputEvent) => {
    if (e !== undefined) {
      setActiveShortcut(e.target.id);
    } else {
      resetActiveShortcut();
    }

    setStartDateString(startDate ? startDate.format('L') : '');
    setStartDate(startDate);
    setEndDateString(endDate ? endDate.format('L') : '');
    setEndDate(endDate);
    onChange({ startDate, endDate });
  };

  const handleStartDate = (e) => {
    const { value } = e.target;
    setStartDateString(value);
    if (value.length === 10) {
      setStartDate(moment(value));
      onChange({ startDate: moment(value), endDate });
    }
  };

  const handleEndDate = (e) => {
    const { value } = e.target;
    setEndDateString(value);
    if (value.length === 10) {
      setEndDate(moment(value));
      onChange({ startDate, endDate: moment(value) });
    }
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
  };

  return (
    <RangePickerContainer data-test={dataTest}>
      <ButtonContainer>
        <StyledSecondaryButton
          className={classnames({ 'selected': selectedShortcut === 'shortcut-last-7' })}
          dataTest={'last-7-button'}
          id="shortcut-last-7"
          onClick={(e) => {
            handleDatesChange({
              startDate: isLast7Days,
              endDate: moment(),
            }, e);
          }}
          status="neutral"
        >
          Last 7 days
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({ 'selected': selectedShortcut === 'shortcut-last-30' })}
          dataTest={'last-30-button'}
          id="shortcut-last-30"
          onClick={(e) => {
            handleDatesChange({
              startDate: isLast30Days,
              endDate: moment(),
            }, e);
          }}
          status="neutral"
        >
          Last 30 days
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({ 'selected': selectedShortcut === 'shortcut-this-month' })}
          dataTest={'this-month-button'}
          id="shortcut-this-month"
          onClick={(e) => {
            handleDatesChange({
              startDate: isThisMonth,
              endDate: moment(),
            }, e);
          }}
          status="neutral"
        >
          This month
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({ 'selected': selectedShortcut === 'shortcut-last-month' })}
          dataTest={'last-month-button'}
          id="shortcut-last-month"
          onClick={(e) => {
            handleDatesChange({
              startDate: isStartOfLastMonth,
              endDate: isEndOfLastMonth,
            }, e);
          }}
          status="neutral"
        >
          Last month
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({ 'selected': selectedShortcut === 'shortcut-this-year' })}
          dataTest={'this-year-button'}
          id="shortcut-this-year"
          onClick={(e) => {
            handleDatesChange({
              startDate: isThisYear,
              endDate: moment(),
            }, e);
          }}
          status="neutral"
        >
          This year
        </StyledSecondaryButton>
        <StyledSecondaryButton
          className={classnames({ 'selected': selectedShortcut === 'shortcut-all-time' })}
          dataTest={'all-time-button'}
          id="shortcut-all-time"
          onClick={(e) => {
            handleDatesChange({
              startDate: null,
              endDate: null,
            }, e);
          }}
          status="neutral"
        >
          All time
        </StyledSecondaryButton>
      </ButtonContainer>

      <CalendarWrapper>
        <InputWrapper>
          <StyledInput
            className="start-input"
            dataTest={`${dataTest}-start`}
            label="From"
            name="start date"
            onChange={handleStartDate}
            placeholder="MM/DD/YYYY"
            value={startDateString}
          />
          <StyledInput
            className="end-input"
            dataTest={`${dataTest}-end`}
            label="To"
            name="end date"
            onChange={handleEndDate}
            placeholder="MM/DD/YYYY"
            value={endDateString}
          />
        </InputWrapper>

        <DateRangePickerStyleOverrides
          data-test={`${dataTest}-calendar`}
        >
          <RangePicker
            daySize={isMobile ? 36.5 : 35}
            enableOutsideDays={enableOutsideDays}
            endDate={endDate}
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
            initialVisibleMonth={() => startDate ? moment(startDate) : moment()}
            isOutsideRange={isOutsideRange}
            keepOpenOnDateSelect
            minimumNights={0}
            monthFormat="MMM YYYY"
            navNext={<NextArrow />}
            navPrev={<PrevArrow />}
            noBorder
            numberOfMonths={1}
            onDatesChange={({ startDate, endDate }) => handleDatesChange({ startDate, endDate })}
            onFocusChange={handleFocusChange}
            startDate={startDate}
          />
        </DateRangePickerStyleOverrides>
      </CalendarWrapper>
    </RangePickerContainer>
  );
};

export { DateRangePicker };
