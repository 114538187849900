/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, zIndex } from 'style';
import debounce from 'lodash/debounce';

import { MultiDownshift } from './MultiDownshift';
import { TypeaheadInput } from './TypeaheadInput';
import { DropdownMenu, DropdownMenuItem } from '../Dropdown';
import { Label } from '../Label';

const TypeaheadWrapper = styled(({ rootRef, ...rest }) => (
  <div ref={rootRef} {...rest} />
))`
  color: ${color.text600};
  font-size: 1.4rem;
`;

const MenuContainer = styled.div`
  position: relative;
`;

const TypeaheadMenu = styled(DropdownMenu)`
  left: 0;
  position: absolute;
  top: calc(100% + 0.4rem);
  width: 100%;
  z-index: ${zIndex.echo};
`;

const TypeaheadLabel = styled(Label)`
  margin: 0;
  padding: 0;
`;

class TypeaheadSearch extends Component {
  constructor(props) {
    super(props);
    this.fetchOnType = debounce(this.fetchOnType.bind(this), 250); // debounce to collect whole word
  }

  state = {
    inputValues: [],
  }

  static getDerivedStateFromProps(props, state) {
    const { items } = props;

    if (state.inputValues.length === 0 && (items && items.length > 0)) {
      return {
        inputValues: items,
      };
    }
    return null;
  }

  fetchOnType = (value) => {
    const { asyncValueFetch } = this.props;
    asyncValueFetch(value)
      .then((res) => {
        this.setState({ inputValues: res.payload.values });
      });
  }

  handleKeyUp = (e) => {
    const { value } = e.target;
    e.persist();

    this.fetchOnType(value);
  }

  downshiftOnChange = (selectedItems) => {
    const { onChange } = this.props;
    this.setState({ inputValues: selectedItems ? selectedItems : [] }, () => {
      onChange(this.state.inputValues);
    });
  }

  render() {
    const {
      className,
      dataTest,
      disabled,
      id,
      renderLabelContent,
      items,
      itemDisabled,
      itemLabel,
      maxListSize,
      placeholder,
      selectedItems: selectedItemsProp,
    } = this.props;

    return (
      <MultiDownshift
        itemToString={itemLabel}
        onChange={this.downshiftOnChange}
        selectedItems={selectedItemsProp}
      >
        {({
          getItemProps,
          getLabelProps,
          getMenuProps,
          toggleItem,
          getRootProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          getInputPropsWithBackspace,
          selectedItems,
        }) => {
          const filteredItems = items
            .filter(
              item => !selectedItems.includes(item)
                  && itemLabel(item).toLowerCase().includes(inputValue.toLowerCase()),
            )
            .slice(0, maxListSize);
          const showMenu = isOpen && !disabled;
          const labelContent = renderLabelContent();

          return (
            <TypeaheadWrapper
              {...getRootProps({ refKey: 'rootRef', className, id })}
            >
              {labelContent && <TypeaheadLabel {...getLabelProps()}>{labelContent}</TypeaheadLabel>}
              <MenuContainer>
                <TypeaheadInput
                  disabled={disabled}
                  onKeyUp={(e) => this.handleKeyUp(e)}
                  onFocus={openMenu}
                  itemLabel={itemLabel}
                  inputProps={{ ...getInputPropsWithBackspace() }}
                  onRemove={toggleItem}
                  selectedItems={selectedItems}
                  inputValue={inputValue}
                  placeholder={placeholder}
                  dataTest={dataTest}
                />
                <TypeaheadMenu
                  hidden={!showMenu}
                  {...getMenuProps({
                    dataTest: dataTest && `${dataTest}-menu`,
                  })}
                >
                  {
                    filteredItems.length === 0
                      ? <DropdownMenuItem disabled>No results found</DropdownMenuItem>
                      : filteredItems.map((item, index) => (
                        <DropdownMenuItem
                          key={itemLabel(item)}
                          {...getItemProps({
                            item,
                            active: highlightedIndex === index,
                            disabled: itemDisabled(item),
                          })}
                        >
                          {itemLabel(item)}
                        </DropdownMenuItem>
                      ))
                  }
                </TypeaheadMenu>
              </MenuContainer>
            </TypeaheadWrapper>
          );
        }}
      </MultiDownshift>
    );
  }
}

TypeaheadSearch.propTypes = {
  asyncValueFetch: PropTypes.func,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  itemDisabled: PropTypes.func,
  itemLabel: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ),
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ),
  renderLabelContent: PropTypes.func,
  maxListSize: PropTypes.number,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
};

TypeaheadSearch.defaultProps = {
  asyncValueFetch: () => {},
  className: undefined,
  dataTest: undefined,
  disabled: false,
  id: undefined,
  itemDisabled: () => false,
  itemLabel: item => item,
  items: [],
  maxListSize: 10,
  onChange: () => {},
  onKeyUp: () => {},
  renderLabelContent: () => {},
  placeholder: 'Enter one or more values',
  selectedItems: [],
};

export { TypeaheadSearch };
