import React from 'react';
import styled, { keyframes } from 'styled-components';
import { color, mixin } from 'style';

const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const SpinnerBubble = styled.div`
  animation: 2s ease-in-out 0s infinite both ${bounceDelay};
  background-color: ${color.sky50};
  border-radius: 100%;
  display: inline-block;
  height: 1.8rem;
  width: 1.8rem;
`;


const SpinnerWrapper = styled.div`
  ${mixin.center};

  > :nth-child(1) {
    animation-delay: -0.50s;
  }

  > :nth-child(2) {
    animation-delay: -0.25s;
  }
`;

const Spinner = () => (
  <SpinnerWrapper>
    <SpinnerBubble />
    <SpinnerBubble />
    <SpinnerBubble />
  </SpinnerWrapper>
);

export { Spinner };
