import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSelectTabRadioGroup = styled.label`
  display: flex;

  > div {
    flex: 1;

    label {
      width: 100%;
    }
  }
`;

const SelectTabRadioGroup = ({
  children,
  className,
  dataTest,
  disabled,
}) => {
  const filteredChildren = children.filter(child => typeof child === 'object');

  return (
    <StyledSelectTabRadioGroup
      className={className}
      data-test={dataTest}
    >
      {React.Children.map(filteredChildren, (child, index) => {
        let classes = child.props.className;

        if (index === 0) {
          classes += ' select-tab-radio--first';
        } else if (index === filteredChildren.length - 1) {
          classes += ' select-tab-radio--last';
        }

        return React.cloneElement(child, {
          className: classes,
          disabled,
        });
      })}
    </StyledSelectTabRadioGroup>
  );
};

SelectTabRadioGroup.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dataTest: PropTypes.string,
};

SelectTabRadioGroup.defaultProps = {
  children: [],
  disabled: false,
  className: undefined,
  dataTest: undefined,
};

export { SelectTabRadioGroup };
