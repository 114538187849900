import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { form, typography } from 'style';

import { Label } from '../Label';
import { Error } from '../Error';

const StyledTextArea = styled.textarea`
  ${({ status }) => form.textarea(status)};
`;

const CharacterCounter = styled.div`
  ${typography.body}
  max-width: 45rem;
  display: flex;
  justify-content: flex-end;
`;

const TextArea = ({
  className,
  dataTest,
  errors,
  id: _id,
  label,
  name,
  onChange,
  type = 'text',
  value,
  characterLimit,
  ...rest
}) => {
  const [characters, setCharacters] = useState(value.length);
  const id = _id || name;

  useEffect(() => {
    if (characterLimit) {
      setCharacters(value.length);
    }
  }, [value]);

  return (
    <div className={className} data-test={dataTest}>
      {label && (
        <Label data-test={dataTest && `${dataTest}-label`} id={id}>
          {label}
        </Label>
      )}
      <StyledTextArea
        data-test={dataTest && `${dataTest}-textarea`}
        id={id}
        name={name}
        status={errors && 'negative'}
        type={type}
        onChange={onChange}
        value={value}
        maxLength={characterLimit}
        {...rest}
      />

      {characterLimit && (
        <CharacterCounter
          data-test={dataTest && `${dataTest}-character-counter`}
        >
          {`${characters} of ${characterLimit}`}
        </CharacterCounter>
      )}

      <Error message={errors} data-test={dataTest && `${dataTest}-error`} />
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  characterLimit: PropTypes.number,
};

TextArea.defaultProps = {
  className: undefined,
  dataTest: undefined,
  errors: undefined,
  value: '',
  label: undefined,
};

export { TextArea };
