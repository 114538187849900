import styled from 'styled-components';
import { border, color, zIndex, elevation } from 'style';

interface DropdownMenuProps {
  dataTest?: string
};

const DropdownMenu = styled.ul.attrs(
  ({ dataTest }: DropdownMenuProps) => ({ 'data-test': dataTest }),
)`
  ${elevation['12']}
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  border: ${border.normal(color.ui15)};
  max-height: 22rem;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 0.4rem);
  width: 100%;
  z-index: ${zIndex.echo};
  left: 0;
`;

export { DropdownMenu };
