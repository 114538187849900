import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';

class MultiDownshift extends Component {
  static propTypes = {
    selectedItem: PropTypes.array,
  };

  static defaultProps = {
    selectedItem: undefined,
  };

  state = {
    selectedItems: [],
    isInitialStateSet: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.isInitialStateSet) {
      return { selectedItems: prevState.selectedItems };
    }

    if (nextProps.selectedItems) {
      return {
        selectedItems: nextProps.selectedItems,
        isInitialStateSet: true,
      };
    }

    return null;
  }

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: '',
        };
      default:
        return changes;
    }
  };

  handleSelection = (selectedItem, downshift) => {
    const callOnChange = () => {
      if (this.props.onSelect) {
        this.props.onSelect(
          this.state.selectedItems,
          this.getStateAndHelpers(downshift),
        );
      }
      if (this.props.onChange) {
        this.props.onChange(
          this.state.selectedItems,
          this.getStateAndHelpers(downshift),
        );
      }
    };

    if (this.state.selectedItems.includes(selectedItem)) {
      this.removeItem(selectedItem, callOnChange);
    } else {
      this.addSelectedItem(selectedItem, callOnChange);
    }
  };

  removeItem = (item, callback) => {
    this.setState(({ selectedItems }) => {
      return {
        selectedItems: selectedItems.filter(i => i !== item),
      };
    }, callback);
  };

  addSelectedItem = (item, callback) => {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [...selectedItems, item],
      }),
      callback,
    );
  };

  inputPropsWithBackspace = ({ inputValue, getInputProps }) => {
    const { selectedItems } = this.state;

    return props => {
      const { onKeyDown, ...rest } = getInputProps(props);

      return {
        onKeyDown: e => {
          if (e.key === 'Backspace' && !inputValue) {
            this.removeItem(selectedItems[selectedItems.length - 1]);
          }
          onKeyDown(e);
        },
        ...rest,
      };
    };
  };

  getStateAndHelpers(downshift) {
    const { selectedItems } = this.state;
    const { inputPropsWithBackspace, handleSelection } = this;
    const getInputPropsWithBackspace = inputPropsWithBackspace(downshift);
    const boundToggleItem = item => (handleSelection(item, downshift));

    return {
      toggleItem: boundToggleItem,
      getInputPropsWithBackspace,
      selectedItems,
      ...downshift,
    };
  }

  render() {
    const { render, children = render, ...props } = this.props;

    return (
      <Downshift
        {...props}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
      >
        {downshift => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

export { MultiDownshift };
