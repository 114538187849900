import styled from 'styled-components';
import calendarIconUrl from '@gsc/icons/svg/calendar-standard.svg';
import { ChevronLeft, ChevronRight, ChevronLeftDouble, ChevronRightDouble } from '@gsc/icons/react';

import { form, typography, font, color, elevation, border, mixin } from 'style';

const DatePickerStyleOverrides = styled.div`
  .DateInput {
    display: block;
    width: 100%;
  }

  .DateInput_input {
    ${form.input.text()}
    background: transparent url(${calendarIconUrl}) no-repeat 0.8rem center;
    background-size: 1.4rem;
    padding-left: 3rem;
    width: 100%;
  }

  &.negative {
    .DateInput_input {
      ${form.input.text('negative')}
      padding-left: 3rem;
    }
  }

  .DateInput_input__disabled {
    font-style: normal;
    background-color: ${color.form.disabled.background};
    border: ${border.normal(color.form.disabled.border)};
    color: ${color.textHint};
  }

  .SingleDatePicker,
  .DateRangePicker,
  [class*='DatePickerInput'] {
    display: block;
  }

  [class*='DatePicker_picker'] {
    display: block;
    top: 5rem !important;
  }

  .DayPicker__withBorder {
    border-radius: ${border.radius.normal};
    ${elevation['12']}
  }

  .DateInput_fang {
    display: none;
  }

  .CalendarMonth_caption {
    position: relative;
    padding: 1.3rem 0 3.8rem;
    text-align: center;
    ${typography.heading4}
    color: ${color.mxDeepOcean};
  }

  .DayPicker_weekHeader {
    top: 4.8rem;
  }

  .DayPicker_weekHeader_li {
    ${typography.bodySmall}
    color: ${color.mxDeepOcean};
    font-weight: ${font.weight.medium};
  }

  .CalendarDay__circle {
    border: 0.1rem transparent solid;
  }

  .CalendarDay {
    ${typography.bodySmall}
    border-color: transparent;
    vertical-align: middle;
    text-align: center;

    &:hover {
      background-color: transparent;
      border-color: transparent;

      .CalendarDay__circle {
        background-color: ${color.mxDeepOcean};
        color: ${color.textWhite};
        border-color: ${color.mxDeepOcean};
      }
    }
  }

  .CalendarDay__today .CalendarDay__circle { /* stylelint-disable-line no-descending-specificity */
    border-color: ${color.mxDeepOcean};
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: transparent;

    .CalendarDay__circle { /* stylelint-disable-line no-descending-specificity */
      background-color: ${color.mxDeepOcean};
    }
  }

  .CalendarDay__blocked_out_of_range{
    color: ${color.textHint};
    cursor: not-allowed;

    &:hover {
      .CalendarDay__circle { /* stylelint-disable-line no-descending-specificity */
        color: ${color.textHint};
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

`;

const PrevArrow = styled(ChevronLeft)`
  ${mixin.size('1.4rem')}
  color: ${color.ui50};
  left: 5rem;
  position: absolute;
  top: 1.6rem;

  &:hover {
    color: ${color.mxDeepOcean};
  }
`;

const NextArrow = styled(ChevronRight)`
  ${mixin.size('1.4rem')}
  color: ${color.ui50};
  position: absolute;
  right: 5rem;
  top: 1.6rem;

  &:hover {
    color: ${color.mxDeepOcean};
  }
`;

const PrevYear = styled(ChevronLeftDouble)`
  ${mixin.size('1.4rem')}
  color: ${color.ui50};
  cursor: pointer;
  display: block;
  left: 0;
  position: absolute;
  top: 1.6rem;

  &:hover {
    color: ${color.mxDeepOcean};
  }

  svg {
    ${mixin.size('1.4rem')}
  }
`;

const NextYear = styled(ChevronRightDouble)`
  ${mixin.size('1.4rem')}
  color: ${color.ui50};
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0;
  top: 1.6rem;

  &:hover {
    color: ${color.mxDeepOcean};
  }

  svg {
    ${mixin.size('1.4rem')}
  }
`;

const DayCircle = styled.div`
  ${mixin.size('2.2rem')}
  border-radius: 50%;
  line-height: 2rem;
  margin: 0 auto;
`;

export {
  DatePickerStyleOverrides,
  DayCircle,
  NextArrow,
  NextYear,
  PrevArrow,
  PrevYear,
};
