import styled from 'styled-components';
import PropTypes from 'prop-types';

import { border, color } from 'style';
import { BaseButton } from './BaseButton';

const SecondaryButton = styled(BaseButton)`
  background-color: ${({ status }) => color.button.secondary[status].default};
  border: ${({ status }) => border.normal(color.button.secondary[status].border)};
  color: ${({ status }) => color.button.secondary[status].text};

  &:focus {
    background-color: ${({ status }) => color.button.secondary[status].hover};
    border-color: ${({ status }) => color.button.secondary[status].hover};
    box-shadow: 0 0 0 0.2rem ${({ status }) => color.button.secondary[status].border};
  }

  &:active {
    background-color: ${({ status }) => color.button.secondary[status].active};
    border-color: ${({ status }) => color.button.secondary[status].border};
    box-shadow: none;
  }

  &:not(.show-loader) {
    &:hover {
      background-color: ${({ status }) => color.button.secondary[status].hover};
      border-color: ${({ status }) => color.button.secondary[status].border};
      color: ${({ status }) => color.button.secondary[status].text};
    }

    &:disabled {
      background-color: ${color.button.secondary.disabled.default};
      border: ${border.normal(color.button.secondary.disabled.border)};
      color: ${color.button.secondary.disabled.text};
    }
  }

`;

SecondaryButton.propTypes = {
  status: PropTypes.oneOf([
    'default',
    'neutral',
    'positive',
    'warning',
    'negative',
    'inverted',
  ]),
};

SecondaryButton.defaultProps = {
  status: 'default',
};

SecondaryButton.displayName = 'SecondaryButton';

export { SecondaryButton };
