import styled from 'styled-components';
import { typography, color } from 'style';

interface HeaderProps {
  dataTest?: string;
  margin?: string;
}

const H1 = styled.h1.attrs(({ dataTest }: HeaderProps) => ({ 'data-test': dataTest }))`
  ${typography.heading1}
  color: ${color.text1};
  margin-bottom: ${({ margin }: HeaderProps) => margin ? margin : '3.2rem'};
`;

const H2 = styled.h2.attrs(({ dataTest }: HeaderProps) => ({ 'data-test': dataTest }))`
  ${typography.heading2}
  color: ${color.text1};
  margin-bottom: ${({ margin }: HeaderProps) => margin ? margin : '2.4rem'};
`;

const H3 = styled.h3.attrs(({ dataTest }: HeaderProps) => ({ 'data-test': dataTest }))`
  ${typography.heading3}
  color: ${color.text1};
  margin-bottom: ${({ margin }: HeaderProps) => margin ? margin : '1.6rem'};
`;

const H4 = styled.h4.attrs(({ dataTest }: HeaderProps) => ({ 'data-test': dataTest }))`
  ${typography.heading4}
  color: ${color.text1};
  margin-bottom: ${({ margin }: HeaderProps) => margin ? margin : '0.8rem'};
`;

const H5 = styled.h5.attrs(({ dataTest }: HeaderProps) => ({ 'data-test': dataTest }))`
  ${typography.heading5}
  color: ${color.text1};
  margin-bottom: ${({ margin }: HeaderProps) => margin ? margin : '0.8rem'};
`;

const H6 = styled.h6.attrs(({ dataTest }: HeaderProps) => ({ 'data-test': dataTest }))`
  ${typography.heading6}
  color: ${color.text1};
  margin-bottom: ${({ margin }: HeaderProps) => margin ? margin : '0.8rem'};
`;

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
};
