import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Downshift from 'downshift';
import { color, zIndex } from 'style';
import { DropdownMenu, DropdownMenuItem } from '../Dropdown';
import { Error } from '../Error';
import { Label } from '../Label';
import { TypeaheadInput } from './TypeaheadInput';

const TypeaheadWrapper = styled(({ rootRef, ...rest }) => (
  <div ref={rootRef} {...rest} />
))`
  color: ${color.text600};
  font-size: 1.4rem;
`;

const MenuContainer = styled.div`
  position: relative;
`;

const TypeaheadMenu = styled(DropdownMenu)`
  left: 0;
  position: absolute;
  top: calc(100% + 0.4rem);
  width: 100%;
  z-index: ${zIndex.echo};
`;

const TypeaheadLabel = styled(Label)`
  margin: 0;
  padding: 0;
`;

const ErrorWrapper = styled(Error)`
  margin: 0.4rem 0;
`;

const SingleValueTypeahead = ({
  asyncValueFetch,
  className,
  dataTest,
  disabled,
  errors,
  id,
  onChange,
  renderLabelContent,
  items,
  itemDisabled,
  itemLabel,
  maxListSize,
  placeholder,
  selectedItem,
  initialSelectedItem,
  inputProps,
  leaveSpaceForError,
}) => {
  return (
    <Downshift
      itemToString={itemLabel}
      onChange={onChange}
      initialSelectedItem={initialSelectedItem || null}
      selectedItem={selectedItem}
    >
      {({
        getItemProps,
        getLabelProps,
        getMenuProps,
        getRootProps,
        highlightedIndex,
        inputValue,
        isOpen,
        openMenu,
        getInputProps,
        clearSelection,
      }) => {
        const filteredItems = items
          .filter(item =>
            itemLabel(item)
              .toLowerCase()
              .includes(inputValue.toLowerCase()),
          )
          .slice(0, maxListSize);
        const showMenu = isOpen && !disabled;
        const labelContent = renderLabelContent();

        const getInputPropsWithBackspace = props => {
          const { onKeyDown, ...rest } = getInputProps(props);

          return {
            onKeyDown: e => {
              if (e.key === 'Backspace' && inputValue.length === 1) {
                clearSelection();
              }
              onKeyDown(e);
            },
            ...rest,
          };
        };

        const handleKeyUp = (e) => {
          const { value } = e.target;
          e.persist();

          asyncValueFetch(value);
        };

        return (
          <TypeaheadWrapper
            {...getRootProps({ refKey: 'rootRef', className, id })}
          >
            {labelContent && (
              <TypeaheadLabel {...getLabelProps()}>
                {labelContent}
              </TypeaheadLabel>
            )}
            <MenuContainer>
              <TypeaheadInput
                disabled={disabled}
                onKeyUp={(e) => handleKeyUp(e)}
                onFocus={openMenu}
                itemLabel={itemLabel}
                inputProps={{ ...getInputPropsWithBackspace(inputProps) }}
                inputValue={inputValue}
                placeholder={placeholder}
                dataTest={dataTest}
                hasError={!!errors}
              />
              <TypeaheadMenu hidden={!showMenu} {...getMenuProps()}>
                {filteredItems.length === 0 ? (
                  <DropdownMenuItem disabled>No results found</DropdownMenuItem>
                ) : (
                  filteredItems.map((item, index) => (
                    <DropdownMenuItem
                      key={itemLabel(item)}
                      {...getItemProps({
                        item,
                        active: highlightedIndex === index,
                        disabled: itemDisabled(item),
                      })}
                    >
                      {itemLabel(item)}
                    </DropdownMenuItem>
                  ))
                )}
              </TypeaheadMenu>
            </MenuContainer>
            {(errors || leaveSpaceForError) && (
              <ErrorWrapper
                message={errors}
                data-test={dataTest && `${dataTest}-error`}
              />
            )}
          </TypeaheadWrapper>
        );
      }}
    </Downshift>
  );
};

SingleValueTypeahead.defaultProps = {
  asyncValueFetch: () => {},
  className: undefined,
  dataTest: 'typeahead',
  disabled: false,
  id: undefined,
  itemDisabled: () => false,
  itemLabel: item => item,
  items: [],
  maxListSize: 10,
  onChange: () => {},
  renderLabelContent: () => {},
  placeholder: 'Enter one or more values',
  selectedItem: '',
};

SingleValueTypeahead.propTypes = {
  asyncValueFetch: PropTypes.func,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  itemDisabled: PropTypes.func,
  itemLabel: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ),
  selectedItem: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  renderLabelContent: PropTypes.func,
  maxListSize: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
};

export { SingleValueTypeahead };
