import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { border, color, mixin, typography } from 'style';
import { HelpCircle } from '@gsc/icons/react';
import carrotUrl from './tooltip_carrot.svg';

const TooltipWrapper = styled.div`
  position: relative;
`;

const StyledHelpCircle = styled(HelpCircle)`
  cursor: pointer;
  display: inline;
  ${mixin.size('1.4rem')};
`;

const TooltipContentWrapper = styled.div`
  left: -1.5rem;
  position: absolute;
  bottom: 1.6rem;
  max-width: 20rem;
  box-sizing: border-box;
  width: max-content;
  padding-bottom: 1.2rem;

  &:after {
    background: transparent url(${carrotUrl}) no-repeat 0 0;
    content: '';
    display: block;
    height: 1rem;
    left: 1.6rem;
    position: absolute;
    bottom: 0.2rem;
    width: 1.6rem;
  }
`;

const TooltipContent = styled.div`
  ${typography.bodySmall}
  background-color: ${color.ui90};
  border-radius: ${border.radius.normal};
  color: ${color.textWhite};
  cursor: default;
  padding: 0.8rem;

  a {
    color: ${color.sky50};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Tooltip = ({ actionLink, children, dataTest }) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);

  return (
    <TooltipWrapper
      data-test={dataTest}
      onMouseLeave={() => setDisplayTooltip(false)}
    >
      <StyledHelpCircle
        data-test={dataTest && `${dataTest}-trigger`}
        onMouseOver={() => setDisplayTooltip(true)}
      />
      {displayTooltip && (
        <TooltipContentWrapper data-test={dataTest && `${dataTest}-wrapper`}>
          <TooltipContent data-test={dataTest && `${dataTest}-content`}>
            {children}
            {actionLink && (
              <div data-test={dataTest && `${dataTest}-action-link`}>
                {actionLink}
              </div>
            )}
          </TooltipContent>
        </TooltipContentWrapper>
      )}
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  actionLink: PropTypes.node,
  dataTest: PropTypes.string,
  children: PropTypes.node,
};

export { Tooltip };
