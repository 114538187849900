/*************************************
  Colors
*************************************/

/* Green */
const green = {
  green10: '#DCF5EB', // Used as the background in success messages.
  green70: '#046C41', // Used for success message text and icons
  green80: '#015733', // Used as the Hover background in creative buttons.
  green90: '#00370C', // Used as the Active background in creative buttons.
};

/* Yellow */
const yellow = {
  yellow10: '#FFF5E5', // Used as the background in warning messages.
  yellow40: '#FFCD4D', // Used as the background in warning buttons.
  yellow50: '#FFBA30', // Used as the Hover background in warning buttons.
  yellow60: '#F5960E', // Used as the Active background in warning buttons.
  yellow70: '#A36000', // Used for warning message text and icons
};

/* Red */
const red = {
  red10: '#FDEAE9', // Used as the background in error messages.
  red70: '#ED001D', // Used as the Default background in destructive buttons.
  red80: '#D0021B', // Used for error message text and icons; and as hover state for destructive buttons
  red90: '#AA0101', // Used as the Active background in destructive buttons.
};

const ui = {
  ui01: '#FFFFFF', // "UI - White" Primarily a background
  ui05: '#FAFAFA', // "Background - Light" Primarily a background
  ui10: '#F5F6F7', // "Background - Mid" Used as a variant of background-light to add differentiation
  ui11: '#F6F7F7', // "Background - Mid" Used as a variant of background-light to add differentiation
  ui15: '#EBEBEB', // "Border - Light (Platinum)" Used for dividers and occasionally as a border
  ui16: '#E5E6E6', // "Border - Light (Platinum)" Used for dividers and occasionally as a border
  ui20: '#DADCDE', // "Border Grey" The default border color for idle elements
  ui30: '#B5B8BE', // "Focus Grey" Default border color for focused elements
  ui50: '#6F7180', // "Icon Grey" Accessible on white, this color is the default for icons
  ui90: '#262B2F', // "Ui - Black (Charleston)" Effectively black
};

/* Text Colors */
const text = {
  text900: '#262B2F',   // The primary text color. Used in headings, section headers, and focused field labels.
  text800: '#384152',   // This color is an intermediary between the primary and secondary text colors. It’s not used often, but is sometimes necessary
  text600: '#525866',   // Used in supporting text and field labels. This is for secondary or non-focused information.
  textHint: '#737685',  // Used for text on disabled elements and on form input placeholder text
  textWhite: '#FFFFFF', // Used primarily in buttons
  textPositive: green.green70,
  textWarning: yellow.yellow70,
  textNegative: red.red70,
};

/* Primary Colors */
const primary = {
  mxDeepOcean: '#196E8C',       // MarketX’s primary color for CTA buttons, links, and things that are interactive
  mxDeepOceanHover: '#085C79',  // Deep Ocean’s hover and focus state
  mxDeepOceanActive: '#125066', // Deep Ocean’s click state
  mxOrangeSoda: '#F45C3A',      // This color is used for illustration, decorative elements, and blocks of color. It’s not accessible on white backgrounds, and should not be used for interaction or as a primary means of communication.
  mxBrightTurq: '#00D9EB',      // Used in our logo. This color is used for illustration, decorative elements, and blocks of color. It’s not accessible on white backgrounds, and should not be used for interaction or as a primary means of communication.
  mxSpaceCadet: '#202958',      // A primary brand color. At the moment, it’s used sparingly. But it’s accessible and works well with the product.
};

/* Status Colors */
const info = {
  info10: '#E5F9FF',
  info50: primary.mxDeepOcean,
  info90: primary.mxDeepOceanActive,
};

const positive = {
  positive10: green.green10,
  positive70: green.green70,
  positive80: green.green80,
  positive90: green.green90,
};

const warning = {
  warning10: yellow.yellow10,
  warning40: yellow.yellow40,
  warning50: yellow.yellow50,
  warning60: yellow.yellow60,
  warning70: yellow.yellow70,
};

const negative = {
  negative10: red.red10,
  negative70: red.red70,
  negative80: red.red80,
  negative90: red.red90,
};

const transparent = {
  transparent: {
    primary: 'rgba(255, 255, 255, .25)',
    secondary: 'rgba(255, 255, 255, .10)',
    heavy: 'rgba(255, 255, 255, .8)',
    inverse: 'rgba(0, 0, 0, .05)',
    heavyInverse: 'rgba(0, 0, 0, .4)',
  },
};

const button = {
  primary: {
    default: {
      default: primary.mxDeepOcean,
      hover: primary.mxDeepOceanHover,
      active: primary.mxDeepOceanActive,
      text: text.textWhite,
    },
    neutral: {
      default: ui.ui50,
      hover: ui.ui50,
      active: ui.ui50,
      text: text.textWhite,
    },
    positive: {
      default: positive.positive70,
      hover: positive.positive80,
      active: positive.positive90,
      text: text.textWhite,
    },
    negative: {
      default: negative.negative70,
      hover: negative.negative80,
      active: negative.negative90,
      text: text.textWhite,
    },
    warning: {
      default: warning.warning40,
      hover: warning.warning50,
      active: warning.warning60,
      text: text.text900,
    },
    disabled: {
      default: ui.ui05,
      hover: ui.ui05,
      active: ui.ui05,
      text: text.textHint,
    },
    inverted: {
      default: ui.ui01,
      hover: ui.ui05,
      active: ui.ui15,
      text: text.text900,
    },
  },
  secondary: {
    default: {
      default: 'transparent',
      hover: ui.ui15,
      active: ui.ui30,
      text: ui.ui90,
      border: ui.ui20,
    },
    neutral: {
      default: 'transparent',
      hover: ui.ui10,
      active: ui.ui10,
      text: text.text600,
      border: ui.ui15,
    },
    positive: {
      default: 'transparent',
      hover: positive.positive10,
      active: positive.positive10,
      text: text.textPositive,
      border: positive.positive70,
    },
    negative: {
      default: 'transparent',
      hover: negative.negative10,
      active: negative.negative10,
      text: text.textNegative,
      border: negative.negative70,
    },
    warning: {
      default: 'transparent',
      hover: warning.warning10,
      active: warning.warning10,
      text: text.textWarning,
      border: warning.warning50,
    },
    disabled: {
      default: 'transparent',
      hover: ui.ui01,
      active: ui.ui01,
      text: text.textHint,
      border: ui.ui05,
    },
    inverted: {
      default: 'transparent',
      hover: ui.ui50,
      active: ui.ui50,
      text: text.textWhite,
      border: ui.ui01,
    },
  },
  tertiary: {
    default: {
      text: primary.mxDeepOcean,
    },
    neutral: {
      text: text.text600,
    },
    positive: {
      text: text.textPositive,
    },
    negative: {
      text: text.textNegative,
    },
    warning: {
      text: text.textWarning,
    },
    disabled: {
      text: text.textHint,
    },
    inverted: {
      text: text.textWhite,
    },
  },
};

const form = {
  default: {
    background: ui.ui01,
    border: ui.ui15,
  },
  focus: {
    border: primary.mxDeepOcean,
  },
  negative: {
    border: negative.negative70,
  },
  disabled: {
    background: ui.ui10,
    border: ui.ui15,
  },
};

const notification = {
  default: {
    background: ui.ui01,
    text: text.text900,
    border: ui.ui90,
  },
  info: {
    background: info.info10,
    text: info.info90,
    border: info.info90,
  },
  positive: {
    background: positive.positive10,
    text: positive.positive90,
    border: positive.positive90,
  },
  negative: {
    background: negative.negative10,
    text: negative.negative90,
    border: negative.negative90,
  },
  warning: {
    background: warning.warning10,
    text: warning.warning70,
    border: warning.warning70,
  },
};

export const color = {
  ...green,
  ...yellow,
  ...red,
  ...ui,
  ...text,
  ...primary,
  ...info,
  ...positive,
  ...warning,
  ...negative,
  ...transparent,
  button,
  form,
  notification,
};
