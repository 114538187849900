import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { color, font } from 'style';

const activeClassName = 'active';
const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  border-radius: 0.4rem;
  color: ${color.text600};
  padding: 0.9rem 0.8rem;
  font-size: 1.4rem;
  line-height: 1.4rem;

  :hover {
    color: ${color.mxDeepOceanHover};
    cursor: pointer;
  }

  &.${activeClassName} {
    background: #DCF2FA;
    color: ${color.mxDeepOcean};
    font-weight: ${font.weight.medium};

    :hover {
      cursor: auto;
      text-decoration: none;
    }
  }
`;

export default StyledNavLink;