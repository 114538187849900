import styled, { css, keyframes } from 'styled-components';
import { color } from 'style';

const skeletonKeyframes = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const Skeleton = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  --base-color: ${color.ui15};
  --highlight-color: ${color.ui10};
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color:${color.ui15};
  border-radius: 0.4rem;
  ${props => props.borderRadius && css`
    border-radius: ${props.borderRadius};
  `}
  ${props => props.circle && css`
    border-radius: 50%;
  `}
  ${props => props.width && css`
    width: ${props.width};
  `}
  ${props => props.height && css`
    height: ${props.height};
  `}
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

  ::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        90deg,
        ${color.ui15},
        ${color.ui10},
        ${color.ui15}
    );
    transform: translateX(-100%);

    animation: ${skeletonKeyframes} ${props => props.duration}s ease-in-out infinite;
  }
`;

Skeleton.defaultProps = {
  width: '100%',
  height: '100%',
  duration: 1.2,
  circle: false,
};

export { Skeleton };
