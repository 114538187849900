export const zIndex = {
  alpha: 9999999,
  bravo: 888888,
  charlie: 77777,
  delta: 6666,
  echo: 555,
  foxtrot: 44,
  golf: 10,
  hotel: 2,
  india: 1,
  zulu: -1,
};
