import
React,
{
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { Close } from '@gsc/icons/react';

import {
  border,
  color,
  mixin,
  typography,
} from 'style';

interface PillProps {
  as?: any,
  children: ReactNode,
  dataTest?: string,
  onClick?: (...args: any[]) => any,
}

const StyledPill = styled.span(({ as, onClick }: PillProps) => `
  ${typography.bodySmall}
  background-color: ${color.ui10};
  border-color: ${color.ui10};
  border-radius: 999rem;
  border: ${border.normal(color.ui10)};
  color: ${color.text1};
  display: inline-block;
  line-height: 2.2rem;
  padding: ${onClick ? '0 2.4rem 0 1.2rem' : '0 1.2rem'};
  position: relative;

  &:hover {
    background-color: ${as === 'a' || as === 'button' ? color.ui15 : color.ui10};
    cursor: ${as === 'a' || as === 'button' ? 'pointer' : 'default'};
  }
`);

const CloseWrapper = styled.div`
  ${mixin.size('2.4rem')}
  cursor: pointer;
  display: inline-block;
  padding: 0.6rem;
  position: absolute;
  right: 0;
  top: -0.1rem;

  &:hover {
    color: ${color.ui50};
  }
`;

const Pill: FC<PillProps & AnchorHTMLAttributes<any> & ButtonHTMLAttributes<any>> = ({
  as,
  children,
  dataTest,
  onClick,
  ...rest
}) => (
  <StyledPill
    as={as}
    data-test={dataTest}
    onClick={onClick}
    {...rest}
  >
    {children}
    {onClick && <CloseWrapper data-test={dataTest && `${dataTest}-close`}><Close onClick={onClick} /></CloseWrapper>}
  </StyledPill>
);

export { Pill };
