import styled from 'styled-components';

import { border, color } from 'style';

// Need this component for the Typeaheads which are not TypeScript yet
// They blow up in testing with the TS version of Pills

const LegacyPill = styled.span`
  background-color: ${color.ui05};
  border-radius: 1.2rem;
  border: ${border.normal(color.ui15)};
  color: ${color.text600};
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0 0.8rem;

  &:hover {
    background-color: ${color.ui15};
  }
`;

export { LegacyPill };
