export const elevation = {
  '00': `
    box-shadow: none;
  `,
  '01': `
    box-shadow: none;
  `,
  '02': `
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.10);
  `,
  '04': `
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.10);
  `,
  '08': `
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.10);
  `,
  '12': `
    box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.10);
  `,
  '16': `
    box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.10);
  `,
  '24': `
    box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.10);
  `,
};
