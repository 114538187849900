import React from 'react';
import styled  from 'styled-components';
import StyledNavLink from './StyledNavLink';
import { Skeleton } from '../SkeletonLoader';

const Tray = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 1.8rem;
  width: 10rem;
`;

const PillTrayNavLinks = styled(StyledNavLink).withConfig({
  shouldForwardProp: (prop) =>
    !['newStyling'].includes(prop),
})`
  flex-shrink: 0;
  ${props => props.newStyling ? `
    height: 2.8rem;
    padding: 0.7rem 0.8rem;
  ` : `
    height: 3.2rem;
  ` }
`;

//  NOTE: this component should no longer be used due to issues with newer versions of react-router-dom. A new component has been created to replace this one in the webapp
const PillTray = ({ items = [], cssClass = '', isLoading = false, newStyling = false }) => (
  <Tray className={cssClass}>
    {isLoading ? (
      <Wrapper>
        {items.map((item, index) => (
          <StyledSkeleton key={index} containerTestId={`${item.dataTest}-loader`}/>
        ))}
      </Wrapper>
    ) : (
      <>
        {items.map((item, index) => (
          <PillTrayNavLinks
            key={index}
            isActive={!!item.isActive ? item.isActive : null}
            newStyling={newStyling}
            className="tray__item-link"
            data-test={item.dataTest}
            exact
            to={item.to}
          >
            {item.text}
          </PillTrayNavLinks>
        ))}
      </>
    )}

  </Tray>
);

export { PillTray };
