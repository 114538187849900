import React, { cloneElement } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { border, color, typography, font } from 'style';
import styled from 'styled-components';

const NavContainer = styled.div`
  ${typography.heading5};
  align-items: stretch;
  border-top: ${border.normal(color.ui15)};
  border-bottom: ${border.normal(color.ui15)};
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLink = styled.span`
  align-self: auto;
  cursor: pointer;
  margin: 0 3rem;

  > * {
    border-bottom: 0.4rem solid transparent;
    color: ${color.text600};
    display: block;
    font-size: 1.6rem;
    font-weight: ${font.weight.normal};
    padding: 1.6rem 0 1.2rem;

    &:not(.disabled):hover {
      color: ${color.ui50};
      border-color: ${color.ui30};
    }

    &.${props => props.activeClassName}:not(.disabled),
    &.${props => props.activeClassName}:not(.disabled):hover {
      color: inherit;
      border-color: ${color.mxBrightTurq};
    }

    &.disabled {
      color: ${color.textHint};
      cursor: not-allowed;
    }
  }
`;

const TabNav = ({ activeClassName, children, className }) => {
  return (
    <NavContainer className={className}>
      {children.map((child) => {
        const { props } = child;

        return (
          <StyledLink
            activeClassName={activeClassName}
            key={props.to}
          >
            {props.disabled
              ? (
                <span
                  className={classnames({ 'disabled': props.disabled })}
                  data-test={props['data-test']}
                >
                  {props.children}
                </span>
              )
              : cloneElement(child, { activeClassName })
            }
          </StyledLink>
        );
      })}
    </NavContainer>
  );
};

TabNav.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TabNav.defaultProps = {
  activeClassName: 'active',
  className: undefined,
};

export { TabNav };
