import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OutsideClick extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: undefined,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  container = React.createRef()

  handleClick = (e) => {
    const { onOutsideClick } = this.props;

    if (this.container.current.contains(e.target)) {
      return;
    }

    onOutsideClick();
  }

  render() {
    const { className, children } = this.props;

    return (
      <div className={className} ref={this.container}>
        {children}
      </div>
    );
  }
}

export { OutsideClick };
