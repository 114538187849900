/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import momentPropTypes from 'react-moment-proptypes';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';

import { Label } from '../Label';
import { Error } from '../Error';
import {
  DatePickerStyleOverrides,
  DayCircle,
  NextArrow,
  NextYear,
  PrevArrow,
  PrevYear,
} from './components';
import { OutsideClick } from '../OutsideClick';

const ConditionalOutsideClickWrap = ({ condition, children }) =>
  condition ? (
    <OutsideClick
      onOutsideClick={() => {
      }}
    >
      {children}
    </OutsideClick>
  ) : (
    children
  );


class DatePicker extends Component {
  state = {
    date: null,
    focused: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (state.date === null && props.initialDate !== null) {
      return {
        date: props.initialDate,
      };
    }

    return null;
  }

  handleChange = (date) => {
    const { onChange } = this.props;

    this.setState({ date });
    onChange(date);
  }

  render() {
    const {
      className,
      dataTest,
      disabled,
      errors,
      id: _id,
      initialVisibleMonth,
      isOutsideRange,
      label,
      name,
      placeholder,
      wrapFocusOut,
    } = this.props;
    const id = _id || name;

    return (
      <div className={className} data-test={dataTest}>
        {label && (
          <Label id={id} data-test={dataTest && `${dataTest}-label`}>
            {label}
          </Label>
        )}
        <DatePickerStyleOverrides
          data-test={dataTest && `${dataTest}-date-picker`}
          className={classnames({ negative: errors })}
        >
          <ConditionalOutsideClickWrap
            condition={wrapFocusOut && this.state.focused}
          >
            <SingleDatePicker
              date={this.state.date}
              daySize={32}
              disabled={disabled}
              focused={this.state.focused}
              hideKeyboardShortcutsPanel={true}
              id={id}
              initialVisibleMonth={initialVisibleMonth}
              isOutsideRange={isOutsideRange}
              navNext={<NextArrow />}
              navPrev={<PrevArrow />}
              noBorder={true}
              numberOfMonths={1}
              onDateChange={this.handleChange}
              onFocusChange={({ focused }) => this.setState({ focused })}
              placeholder={placeholder}
              renderDayContents={(day) => (
                <DayCircle className="CalendarDay__circle">
                  {day.format('D')}
                </DayCircle>
              )}
              renderMonthElement={({ month, onYearSelect }) => (
                <Fragment>
                  <PrevYear
                    onClick={() => onYearSelect(month, month.year() - 1)}
                    role="button"
                  />
                  {month.format('MMMM')} {month.year()}
                  <NextYear
                    onClick={() => onYearSelect(month, month.year() + 1)}
                    role="button"
                  />
                </Fragment>
              )}
              transitionDuration={0}
            />
          </ConditionalOutsideClickWrap>
        </DatePickerStyleOverrides>

        <Error message={errors} data-test={dataTest && `${dataTest}-error`} />
      </div>
    );
  }
}

DatePicker.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  id: PropTypes.string,
  initialDate: momentPropTypes.momentObj,
  initialVisibleMonth: momentPropTypes.momentObj,
  isOutsideRange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  wrapFocusOut: PropTypes.bool,
};

DatePicker.defaultProps = {
  className: undefined,
  dataTest: undefined,
  disabled: false,
  errors: undefined,
  id: undefined,
  initialDate: null,
  initialVisibleMonth: null,
  isOutsideRange: undefined,
  label: undefined,
  name: undefined,
  onChange: () => {},
  placeholder: '',
  wrapFocusOut: false,
};

export {
  DatePicker,
};
