import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ className, dataTest }) => (
  <div className={className} data-test={dataTest}>
    <svg fill="currentColor" height="100%" width="100%" viewBox="0 0 64 64">
      <g strokeWidth="0">
        <circle cx="24" cy="0" transform="translate(32,32)" r="5.69782">
          <animate attributeName="r" dur="750ms" values="8;7;6;5;4;3;2;1;8" repeatCount="indefinite" />
        </circle>
        <circle cx="16.970562748477143" cy="16.97056274847714" transform="translate(32,32)" r="6.69782">
          <animate attributeName="r" dur="750ms" values="1;8;7;6;5;4;3;2;1" repeatCount="indefinite" />
        </circle>
        <circle cx="1.4695761589768238e-15" cy="24" transform="translate(32,32)" r="7.69782">
          <animate attributeName="r" dur="750ms" values="2;1;8;7;6;5;4;3;2" repeatCount="indefinite" />
        </circle>
        <circle cx="-16.97056274847714" cy="16.970562748477143" transform="translate(32,32)" r="3.11523">
          <animate attributeName="r" dur="750ms" values="3;2;1;8;7;6;5;4;3" repeatCount="indefinite" />
        </circle>
        <circle cx="-24" cy="2.9391523179536475e-15" transform="translate(32,32)" r="1.69782">
          <animate attributeName="r" dur="750ms" values="4;3;2;1;8;7;6;5;4" repeatCount="indefinite" />
        </circle>
        <circle cx="-16.970562748477143" cy="-16.97056274847714" transform="translate(32,32)" r="2.69782">
          <animate attributeName="r" dur="750ms" values="5;4;3;2;1;8;7;6;5" repeatCount="indefinite" />
        </circle>
        <circle cx="-4.408728476930472e-15" cy="-24" transform="translate(32,32)" r="3.69782">
          <animate attributeName="r" dur="750ms" values="6;5;4;3;2;1;8;7;6" repeatCount="indefinite" />
        </circle>
        <circle cx="16.970562748477136" cy="-16.970562748477143" transform="translate(32,32)" r="4.69782">
          <animate attributeName="r" dur="750ms" values="7;6;5;4;3;2;1;8;7" repeatCount="indefinite" />
        </circle>
      </g>
    </svg>
  </div>
);

Loader.displayName = 'Loader';

Loader.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
};

export { Loader };
