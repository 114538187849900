import React from 'react';
import styled from 'styled-components';

import { ChevronDown, ChevronUp } from '@gsc/icons/react';
import { border, color, form, mixin } from 'style';

const DropdownArrow = styled(({ isOpen, ...rest }) => {
  const Arrow = isOpen ? ChevronUp : ChevronDown;
  return <Arrow {...rest} />;
})`
  ${mixin.size('1.2rem')}
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);

  path {
    fill: none;
    stroke: ${color.ui50};
  }
`;

const Wrapper = ({ children, rootRef, isOpen, dataTest, isPlaceholder, ...rest }) => (
  <div {...rest} ref={rootRef} data-test={dataTest}>
    {children}
    <DropdownArrow isOpen={isOpen} />
  </div>
);

interface DropdownActivatorProps {
  dataTest?: string,
  disabled?: boolean,
  isOpen?: boolean,
  isPlaceholder?: boolean,
  rootRef?:
    | ((...args: any[]) => any)
    | {
        current?: any
      },
  status?: 'default' | 'negative'
};

const DropdownActivator = styled(Wrapper)`
  color: ${({ isPlaceholder }) => isPlaceholder ? color.textHint : color.text600 };
  padding: 1.2rem;
  position: relative;

  ${({ status }) => form.input.text(status)}

  ${({ isOpen }) =>
    isOpen &&
    `
      border: ${border.normal(color.mxDeepOcean)};
    `}

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${color.form.disabled.background};
    border: ${border.normal(color.form.disabled.border)};
    color: ${color.textHint};
    cursor: not-allowed;
  `}
`;

export { DropdownActivator };
