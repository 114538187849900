/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { border, color, form } from 'style';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownActivator as OriginalDropdownActivator,
} from '../Dropdown';
import { Label } from '../Label';
import clockUrl from '@gsc/icons/svg/clock.svg';
import { Error } from '../Error';

const DropdownWrapper = styled(({ rootRef, ...rest }) => (
  <div ref={rootRef} {...rest} />
))`
  color: ${color.text600};
  font-size: 1.4rem;
`;

const MenuContainer = styled.div`
  position: relative;
`;

const DropdownActivator = styled(OriginalDropdownActivator)`
  background: transparent url('${clockUrl}') no-repeat 0.8rem center;
  background-size: 1.6rem;

  ${({ disabled }) => disabled && `
    background-color: ${color.form.disabled.background};
    border: ${border.normal(color.form.disabled.border)};
    color: ${color.textHint};
    cursor: not-allowed;
  `}
`;

const TimePickerLabel = styled(Label)`
  margin: 0;
  padding: 0;
`;

const TextInput = styled.input`
  ${form.input.text()}
  border: 0;
  padding: 0;
  margin-left: 1.6rem;
  flex: 1;


  &[placeholder$='AM']::placeholder,
  &[placeholder$='PM']::placeholder {
    color: ${color.text600};
  }

  /* This must be after the [placeholder$='AM'] selectors to override them */
  &:disabled::placeholder {
    color: ${color.textHint};
  }

  &:focus {
    box-shadow: none;
    border: 0;
  }

  &:disabled {
    background-color: transparent;
    border: 0;
  }
`;

export const timeGenerator = (minuteStep, disableMidnight) => {
  const times = [];
  const amPm = ['AM', 'PM'];

  for (let startTime = 0; startTime < 24 * 60; startTime += minuteStep) {
    // getting hours of day in 0-24 format
    const hours = Math.floor(startTime / 60);
    // getting minutes of the hour in 0-55 format
    const minutes = startTime % 60;
    // eslint-disable-next-line max-len
    times.push(((hours === 12 || hours === 0) ? 12 : hours % 12) + ':' + ('0' + minutes).slice(-2) + ' ' + amPm[Math.floor(hours / 12)]);
  }

  if (disableMidnight) {
    times.shift();

    if (minuteStep !== 1) {
      times.push('11:59 PM');
    }
  }

  return times;
};

class TimePickerInput extends Component {
  state = {
    times: timeGenerator(this.props.minuteStep, this.props.disableMidnight),
  };

  input = React.createRef();

  focusInput = selectItem => {
    selectItem(null);
    this.input.current.focus();
  };

  unFocusInput = () => {
    this.input.current.blur();
  };

  render() {
    const {
      className,
      dataTest,
      initialSelectedItem,
      disabled,
      id,
      itemDisabled,
      itemLabel,
      onChange,
      placeholder,
      renderLabelContent,
      selectedItem,
      errors,
    } = this.props;
    const labelContent = renderLabelContent();
    const { times } = this.state;

    return (
      <Downshift
        itemToString={item => (item ? itemLabel : '')}
        onChange={onChange}
        initialSelectedItem={initialSelectedItem || null}
        selectedItem={selectedItem || undefined}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getRootProps,
          getToggleButtonProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          selectItem,
        }) => {
          const showMenu = isOpen && !disabled && !!times.length;
          const displayValue = selectedItem
            ? itemLabel(selectedItem)
            : placeholder;

          return (
            <DropdownWrapper
              data-test={dataTest}
              {...getRootProps({ refKey: 'rootRef', id, className })}
            >
              {labelContent && (
                <TimePickerLabel
                  data-test={dataTest && `${dataTest}-label`}
                  {...getLabelProps()}
                >
                  {labelContent}
                </TimePickerLabel>
              )}

              {/* This div is to help layouts in Mission Builder so the error isn't moved around */}
              <div>
                <MenuContainer>
                  <DropdownActivator
                    errors={errors}
                    data-test={dataTest && `${dataTest}-activator`}
                    {...getToggleButtonProps({
                      disabled,
                      isOpen: showMenu,
                      status: errors ? 'negative' : 'default',
                      onClick: () => this.focusInput(selectItem),
                    })}
                  >
                    <TextInput
                      data-test={dataTest && `${dataTest}-input`}
                      {...getInputProps({
                        isOpen,
                        placeholder: displayValue,
                        ref: this.input,
                        disabled,
                        onKeyDown: event => {
                          if (event.key === 'Enter') {
                            this.unFocusInput();
                          }
                        },
                      })}
                    />
                  </DropdownActivator>
                  <DropdownMenu
                    dataTest={dataTest && `${dataTest}-menu`}
                    hidden={!showMenu}
                    {...getMenuProps()}
                  >
                    {times
                      .filter(item => !inputValue || item.includes(inputValue))
                      .map((item, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <DropdownMenuItem
                          {...getItemProps({
                            'dataTest': dataTest && `${dataTest}-menu-item-${index}`,
                            active: highlightedIndex === index,
                            disabled: itemDisabled(item),
                            item,
                            key: itemLabel(item),
                            onClick: this.unFocusInput,
                          })}
                        >
                          {itemLabel(item)}
                        </DropdownMenuItem>
                      ))}
                  </DropdownMenu>
                </MenuContainer>

                <Error
                  message={errors}
                  data-test={dataTest && `${dataTest}-error`}
                />
              </div>
            </DropdownWrapper>
          );
        }}
      </Downshift>
    );
  }
}

TimePickerInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableMidnight: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  id: PropTypes.string,
  initialSelectedItem: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  itemDisabled: PropTypes.func,
  itemLabel: PropTypes.func,
  minuteStep: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  renderLabelContent: PropTypes.func,
  selectedItem: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),

};

TimePickerInput.defaultProps = {
  className: undefined,
  dataTest: undefined,
  disabled: false,
  disableMidnight: false,
  errors: undefined,
  id: undefined,
  initialSelectedItem: undefined,
  itemDisabled: () => false,
  itemLabel: item => item,
  minuteStep: 15,
  onChange: () => {},
  placeholder: 'Select a time',
  renderLabelContent: () => {},
  selectedItem: undefined,
};

export { TimePickerInput };
