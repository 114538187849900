import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { border, color, elevation } from 'style';

const StyledTile = styled.div`
  background-color: ${color.ui01};
  border-radius: ${border.radius.normal};
  ${elevation['04']}
  padding: ${({ isPaddingBottom }) => isPaddingBottom ? '2.4rem' : '2.4rem 2.4rem 0' };
`;

const Tile = ({
  className,
  dataTest,
  children,
  isPaddingBottom,
}) =>(
  <StyledTile
    className={className}
    data-test={dataTest}
    isPaddingBottom={isPaddingBottom}
  >
    {children}
  </StyledTile>
);

Tile.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  isPaddingBottom: PropTypes.bool,
};

Tile.defaultProps = {
  children: [],
  className: undefined,
  dataTest: undefined,
  isPaddingBottom: true,
};

export {
  Tile,
};
