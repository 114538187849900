/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import styled from 'styled-components';

import { color } from 'style';
import { DropdownMenu } from './DropdownMenu';
import { DropdownMenuItem } from './DropdownMenuItem';
import { DropdownActivator } from './DropdownActivator';
import { Error } from '../Error';
import { Label } from '../Label';

const DropdownWrapper = styled(({ rootRef, ...rest }) => (
  <div ref={rootRef} {...rest} />
))`
  color: ${color.text600};
  font-size: 1.4rem;

  &:hover {
    cursor: default;
  }
`;

const MenuContainer = styled.div`
  position: relative;
`;

const Hidden = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
`;

const DropdownLabel = styled(Label)`
  margin: 0;
  padding-bottom: 0.4rem;
`;

const ErrorWrapper = styled(Error)`
  margin: 0.4rem 0;
`;

class Dropdown extends Component {
  input = React.createRef();

  focusInput = () => {
    this.input.current.focus();
  };

  render() {
    const {
      className,
      initialSelectedItem,
      disabled,
      errors,
      id,
      itemDisabled,
      itemLabel,
      itemKey,
      items,
      onChange,
      placeholder,
      renderLabelContent,
      selectedItem,
      dataTest,
      inputProps,
      leaveSpaceForError,
    } = this.props;
    const labelContent = renderLabelContent();

    return (
      <Downshift
        itemToString={itemLabel}
        onChange={onChange}
        initialSelectedItem={initialSelectedItem || null}
        selectedItem={selectedItem || undefined}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getRootProps,
          getToggleButtonProps,
          highlightedIndex,
          isOpen,
          selectedItem,
        }) => {
          const showMenu = isOpen && !disabled && !!items.length;
          const displayValue = selectedItem
            ? itemLabel(selectedItem)
            : placeholder;

          return (
            <DropdownWrapper
              {...getRootProps({ refKey: 'rootRef', id, className })}
              data-test={dataTest}
            >
              {labelContent && (
                <DropdownLabel
                  {...getLabelProps()}
                  data-test={`${dataTest}-label`}
                >
                  {labelContent}
                </DropdownLabel>
              )}
              <div>
                <MenuContainer>
                  <DropdownActivator
                    {...getToggleButtonProps({
                      disabled,
                      isOpen: showMenu,
                      isPlaceholder: !selectedItem,
                      onClick: this.focusInput,
                      status: errors ? 'negative' : 'default',
                      dataTest: `${dataTest}-activator`,
                    })}
                  >
                    <Hidden>
                      <input
                        {...getInputProps({
                          ref: this.input,
                          disabled,
                          ...inputProps,
                        })}
                      />
                    </Hidden>
                    {displayValue}
                  </DropdownActivator>
                  <DropdownMenu hidden={!showMenu} {...getMenuProps()}>
                    {items.map((item, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <DropdownMenuItem
                        {...getItemProps({
                          item,
                          key: itemKey ? itemKey(item) : itemLabel(item),
                          active: highlightedIndex === index,
                          disabled: itemDisabled(item),
                          dataTest: item.dataTest,
                        })}
                      >
                        {itemLabel(item)}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenu>
                </MenuContainer>
                {(errors || leaveSpaceForError) && (
                  <ErrorWrapper
                    message={errors}
                    data-test={dataTest && `${dataTest}-error`}
                  />
                )}
              </div>
            </DropdownWrapper>
          );
        }}
      </Downshift>
    );
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string,
  initialSelectedItem: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  ),
  itemDisabled: PropTypes.func,
  itemLabel: PropTypes.func,
  itemKey: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  renderLabelContent: PropTypes.func,
  selectedItem: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

Dropdown.defaultProps = {
  className: undefined,
  dataTest: 'dropdown',
  disabled: false,
  errors: undefined,
  id: undefined,
  initialSelectedItem: undefined,
  itemDisabled: () => false,
  itemLabel: item => item,
  itemKey: undefined,
  items: [],
  onChange: () => {},
  placeholder: 'Choose',
  renderLabelContent: () => {},
  selectedItem: undefined,
};

export { Dropdown };
