import React, { FC, AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import { color } from 'style';

const openNewTab = isNewTab =>
  isNewTab ? { rel: 'noopener', target: '_blank' } : {};

interface LinkProps {
  as?: any;
  className?: string;
  component?: any;
  dataTest?: string;
  href?: string;
  variant?: string;
  isNewTab?: boolean;
  isRoute?: boolean;
  to?: string;
};

const LinkStyled = styled.a<LinkProps>`
  color: ${({ variant }) => variant === 'inverted' ? color.sky50 : color.mxDeepOcean};
  cursor: pointer;
  padding: 0;
  text-decoration: none;

  &:hover {
    color: ${({ variant }) => variant === 'inverted' ? color.sky50 : color.mxDeepOcean};
    text-decoration: underline;
  }
`;

const Link: FC<LinkProps & AnchorHTMLAttributes<any>> = ({
  children,
  className,
  component,
  dataTest,
  href,
  isNewTab,
  to,
  variant,
  ...rest
}) => {
  return (
    <LinkStyled
      as={component && component}
      href={href}
      to={to}
      variant={variant}
      className={className}
      data-test={dataTest}
      {...openNewTab(isNewTab)}
      {...rest}
    >
      {children}
    </LinkStyled>
  );
};

export { Link };
