import styled from 'styled-components';
import PropTypes from 'prop-types';

import { border, color } from 'style';
import { BaseButton } from './BaseButton';

const PrimaryButton = styled(BaseButton)`
  background-color: ${({ status }) => color.button.primary[status].default};
  border: ${({ status }) => border.normal(color.button.primary[status].default)};
  color: ${({ status }) => color.button.primary[status].text};

  &:focus {
    background-color: ${({ status }) => color.button.primary[status].hover};
    border-color: ${({ status }) => status === 'inverted' ? color.text1 : color.ui01};
    box-shadow: 0 0 0 0.2rem ${({ status }) => color.button.primary[status].hover};
  }

  &:active {
    background-color: ${({ status }) => color.button.primary[status].active};
    border-color: ${({ status }) => color.button.primary[status].active};
    box-shadow: none;
  }

  &:not(.show-loader) {
    &:hover {
      background-color: ${({ status }) => color.button.primary[status].hover};
      border-color: ${({ status }) => color.button.primary[status].hover};
      color: ${({ status }) => color.button.primary[status].text};
    }

    &:disabled {
      background-color: ${color.button.primary.disabled.default};
      border: ${border.normal(color.button.primary.disabled.default)};
      color: ${color.button.primary.disabled.text};
    }
  }
`;

PrimaryButton.propTypes = {
  status: PropTypes.oneOf([
    'default',
    'neutral',
    'positive',
    'warning',
    'negative',
    'inverted',
  ]),
};

PrimaryButton.defaultProps = {
  status: 'default',
};

PrimaryButton.displayName = 'PrimaryButton';

export { PrimaryButton };
