import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, typography } from 'style';

const StyledLabel = styled.label`
  ${typography.label}
  color: ${color.text600};
  font-size: 1.4rem;
  position: relative;
  padding-bottom: 0.8rem;
  cursor: pointer;
  line-height: 2rem;
  display: ${props => props.isInline ? 'inline-block' : 'block'};
`;

const Label = ({
  children,
  className,
  dataTest,
  id,
  isInline,
  ...rest
}) => (
  <StyledLabel
    className={className}
    data-test={dataTest}
    htmlFor={id}
    isInline={isInline}
    {...rest}
  >
    {children}
  </StyledLabel>
);

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  id: PropTypes.string,
  isInline: PropTypes.bool,
};

Label.defaultProps = {
  children: [],
  className: undefined,
  dataTest: undefined,
  id: undefined,
  isInline: false,
};

export { Label };
