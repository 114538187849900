/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { border, color, typography, form, bootstrapOverrides } from 'style';
import { Error } from '../Error';

const TextInlineInput = styled.input`
  ${form.input.text()}
  ${bootstrapOverrides.textInline}
  ${typography.heading1};
  padding: 0.3rem 1rem;
  border-color: ${({ errors }) => errors ? color.negative50 : 'transparent'};
  border-radius: ${border.radius.normal};
  color: ${color.text1};
  cursor: text;
  position: relative;

  &:hover {
    border-color: ${({ errors }) => errors ? color.negative50 : color.form.default.border};
  }

  &:focus {
    ${bootstrapOverrides.textInline}
    background-color: ${color.form.default.background};
    border-radius: ${border.radius.normal};
    border: ${border.normal(color.form.focus.border)};
  }
`;

class TextInline extends Component {
  state = {
    internalValue: '',
    previousValue: '',
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;

    if (state.internalValue.trim() === '' && (value && value.trim() !== '')) {
      return {
        internalValue: props.value,
      };
    }
    return null;
  }

  input = React.createRef();

  handleChange = (e) => {
    const { target: { value } } = e;
    this.setState(prevState => ({
      ...prevState,
      internalValue: value,
      previousValue: prevState.internalValue,
    }));

    const { onChange } = this.props;
    onChange({ target: { value } });
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  handleBlur = (e) => {
    const { target: { value } } = e;
    const { onBlur } = this.props;
    const { internalValue, previousValue } = this.state;

    if (internalValue.trim() === '') {
      this.setState(prevState => ({
        ...prevState,
        internalValue: previousValue,
      }), () => {
        const { internalValue } = this.state;
        this.input.current.value = internalValue;
        onBlur({ target: { value: internalValue } });
      });
    } else {
      this.setState(prevState => ({
        ...prevState,
      }));

      onBlur({ target: { value } });
    }
  }

  render() {
    const { internalValue } = this.state;

    const {
      className,
      dataTest,
      defaultValue,
      disabled,
      errors,
      id: _id,
      maxLength,
      name,
      onFocus,
      placeholder,
      value,
    } = this.props;
    const id = _id || name;

    return (
      <div className={className} data-test={dataTest}>
        <TextInlineInput
          data-test={dataTest && `${dataTest}-input`}
          defaultValue={defaultValue}
          disabled={disabled}
          errors={errors && errors.length > 0}
          id={id}
          maxLength={maxLength}
          name={name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={onFocus}
          onKeyPress={this.handleKeyPress}
          placeholder={placeholder}
          ref={this.input}
          title={internalValue}
          value={value}
        />

        <Error
          message={errors}
          dataTest={dataTest && `${dataTest}-input-error`}
        />
      </div>
    );
  }
}

TextInline.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  defaultValue: PropTypes.string,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

TextInline.defaultProps = {
  className: undefined,
  dataTest: undefined,
  defaultValue: undefined,
  errors: undefined,
  maxLength: undefined,
  name: undefined,
  onBlur: () => {},
  onFocus: () => {},
  placeholder: undefined,
  value: undefined,
};

export { TextInline };
