import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, transition, zIndex } from 'style';

const RorL = isRight => (isRight ? 'right' : 'left');

const Wrapper = styled.div`
  position: relative;
`;

const Slideout = styled.div`
  position: absolute;
  height: 100%;
  z-index: ${zIndex.india};
  ${({ isRight }) => `${RorL(isRight)}: 0;`}
`;
Slideout.displayName = 'Slideout';

const slideAnimation = ({ isOpen, slideoutRef, isRight }) => {
  const margin = `margin-${RorL(isRight)}`;
  const slideoutWidth =
    (slideoutRef.current && slideoutRef.current.clientWidth) || 0;

  return `
    transition: ${transition.primary(margin)};
    ${isOpen && `${margin}: ${slideoutWidth}px;`}
  `;
};

const Main = styled.div`
  background-color: ${color.ui01};
  height: 100%;
  position: relative;
  z-index: ${zIndex.hotel};

  ${slideAnimation}
`;
Main.displayName = 'Main';

class InlineSlideout extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    isRight: PropTypes.bool,
    renderSlideout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: [],
    className: '',
    isOpen: false,
    isRight: false,
  };

  slideout = createRef();

  render() {
    const { children, className, renderSlideout, ...rest } = this.props;

    return (
      <Wrapper className={className}>
        <Slideout ref={this.slideout} {...rest}>
          {renderSlideout()}
        </Slideout>
        <Main {...rest} slideoutRef={this.slideout}>
          {children}
        </Main>
      </Wrapper>
    );
  }
}

export { InlineSlideout };
