import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, form } from 'style';

import { Label } from '../Label';
import { Error } from '../Error';

const StyledTextInput = styled.input`
  ${({ status }) => form.input.text(status)};
`;

const Input = React.forwardRef(({
  className,
  dataTest,
  errors,
  id: _id,
  isErrorable,
  label,
  name,
  onChange,
  type = 'text',
  value,
  inputProps,
  ...rest
}, ref) => {
  const id = _id || name;

  return (
    <div className={className} data-test={dataTest}>
      {label && (
        <Label data-test={dataTest && `${dataTest}-label`} id={id}>
          {label}
        </Label>
      )}
      <StyledTextInput
        data-test={dataTest && `${dataTest}-input`}
        id={id}
        name={name}
        onChange={onChange}
        ref={ref}
        status={errors && 'negative'}
        type={type}
        value={value}Z
        {...inputProps}
        {...rest}
      />
      {isErrorable && (
        <Error message={errors} data-test={dataTest && `${dataTest}-error`} />
      )}
    </div>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string,
  isErrorable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  className: undefined,
  dataTest: undefined,
  errors: undefined,
  isErrorable: true,
  value: '',
  label: undefined,
};

export { Input };
