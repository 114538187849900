import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mixin } from 'style';
import { HelpCircle } from '@gsc/icons/react';
import ReactTooltip from 'react-tooltip';
import uuid from 'uuid';

const StyledHelpCircle = styled(HelpCircle)`
  cursor: pointer;
  display: inline;
  ${mixin.size('1.4rem')};
`;

const TooltipContent = styled.div`
  padding: 0;
  display: inline-block;
  max-width: 20rem;
  a {
    color:#00D9EB;
    text-decoration: underline;
  }
`;

const HoverContent = styled.div.attrs(({ dataTest }) => ({ 'data-test': dataTest }))`
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`;

const MarketXTooltip = ({ children, dataTest, hoverContent }) => {
  const id = uuid.v4();

  return (
    <>
      <HoverContent data-tip data-for={id} dataTest={dataTest && `${dataTest}-trigger`}>
        {hoverContent ? (
          hoverContent
        ) : (
          <StyledHelpCircle />
        )}
      </HoverContent>
      <ReactTooltip
        clickable={true}
        id={id}
        effect="solid"
        delayHide={100}
        className="container__tooltip"
      >
        <TooltipContent data-test={dataTest && `${dataTest}-content`}>
          {children}
        </TooltipContent>
      </ReactTooltip>
    </>
  );
};

MarketXTooltip.propTypes = {
  dataTest: PropTypes.string,
  children: PropTypes.node,
};

export { MarketXTooltip };
